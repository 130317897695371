import { executeBasicNetworkCall } from "lib/Utils";

export function requestResetPassword(email, success, failure) {
    return (dispatch, getState) => {
        const json = JSON.stringify({ email: email });

        executeBasicNetworkCall("request_reset_password", json, "application/json", "POST", getState, (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                if (x.error) {
                    failure(x.message);
                } else {
                    //take user to their home screen
                    success(x.message);
                }
            }
        });
    };
}

export function validateResetCode(key, callback = () => {}) {
    return (dispatch, getState) => {
        const json = JSON.stringify({ key: key });

        executeBasicNetworkCall("validate_password_reset_key", json, "application/json", "POST", getState, (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                if (x.error) {
                    callback(x.message);
                }
            }
        });
    };
}

export function resetPassword(key, password, successCallback = () => {}, failureCallback = () => {}) {
    return (dispatch, getState) => {
        const json = JSON.stringify({ key: key, password: password });

        executeBasicNetworkCall("change_password", json, "application/json", "POST", getState, (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                if (x.error) {
                    failureCallback(x.message);
                } else {
                    successCallback(x.message);
                }
            }
        });
    };
}
