import createReducer from "../../lib/createReducer";
import * as types from "../actions/types";
import { ControlLevel, CottonPrimarySeeds, DEV_PREFILL } from "GSAT/Constants";

function herbicideTotal(state) {
    // what herbicide sliders are shown?
    let total = 0;

    total += state.lowerUseRate;
    total += state.reliableActivation;
    if (state.hoodedSprayer) {
        total += state.extendedResidualControl;
    }
    if (state.controlLevel !== ControlLevel.Good || state.hoodedSprayer) {
        total += state.improvedQualityFromLateSeasonWeedControl;
    }
    if (state.traitPlatform === 0) {
        total += state.herbicideResistanceManagement;
    }
    return total;
}

const DEFAULT_COTTON = {
    cottonSelected: DEV_PREFILL,
    useBayerSeed: DEV_PREFILL,
    primarySeed: DEV_PREFILL ? CottonPrimarySeeds.Deltapine : null,

    lowerUseRate: 0,
    reliableActivation: 0,
    extendedResidualControl: 0,
    improvedQualityFromLateSeasonWeedControl: 0,
    herbicideResistanceManagement: 0,

    totalHerbicide: 0,

    traitPlatform: DEV_PREFILL ? 0 : null,
    controlLevel: DEV_PREFILL ? ControlLevel.GoodHoodedSprayer : null,
    cottonAcres: DEV_PREFILL ? 1500 : "",
    cottonMarketPrice: 0.0,

    tills: DEV_PREFILL ? true : null,
    hoodedSprayer: DEV_PREFILL ? true : null,
};

export const agCalcCottonHerbicide = createReducer(DEFAULT_COTTON, {
    [types.SET_COTTON_SELECTED](state, action) {
        let newState = Object.assign({}, state);
        newState.cottonSelected = action.data;
        return newState;
    },

    [types.SET_COTTON_ACRES](state, action) {
        let newState = Object.assign({}, state);
        newState.cottonAcres = action.data;
        return newState;
    },

    [types.SET_COTTON_MARKET_PRICE](state, action) {
        let newState = Object.assign({}, state);
        newState.cottonMarketPrice = action.data;
        return newState;
    },

    [types.SET_COTTON_TILLS](state, action) {
        let newState = Object.assign({}, state);
        newState.tills = action.data;
        return newState;
    },

    [types.SET_COTTON_CONTROL_LEVEL](state, action) {
        let newState = Object.assign({}, state);
        newState.controlLevel = action.data;
        newState.totalHerbicide = herbicideTotal(newState);
        return newState;
    },

    [types.SET_COTTON_TRAIT_PLATFORM](state, action) {
        let newState = Object.assign({}, state);
        newState.traitPlatform = action.data;
        newState.totalHerbicide = herbicideTotal(newState);
        return newState;
    },
    [types.SET_USE_HOODED_SPRAYER](state, action) {
        let newState = Object.assign({}, state);
        newState.hoodedSprayer = action.data;
        if (newState.controlLevel !== null) {
            newState.controlLevel = newState.controlLevel.includes("hoodedsprayer")
                ? newState.controlLevel.replace("hoodedsprayer", "")
                : newState.controlLevel + "hoodedsprayer";
        }
        return newState;
    },
    [types.SET_COTTON_LOWER_USE_RATE](state, action) {
        let newState = Object.assign({}, state);
        if (action.data !== null) {
            newState.lowerUseRate = action.data;
        } else {
            newState.lowerUseRate = 0;
        }
        newState.totalHerbicide = herbicideTotal(newState);
        return newState;
    },
    [types.SET_COTTON_RELIABLE_ACTIVATION](state, action) {
        let newState = Object.assign({}, state);
        if (action.data !== null) {
            newState.reliableActivation = action.data;
        } else {
            newState.reliableActivation = 0;
        }
        newState.totalHerbicide = herbicideTotal(newState);
        return newState;
    },
    [types.SET_COTTON_EXTENDED_RESIDUAL_CONTROL](state, action) {
        let newState = Object.assign({}, state);
        if (action.data !== null) {
            newState.extendedResidualControl = action.data;
        } else {
            newState.extendedResidualControl = 0;
        }
        newState.totalHerbicide = herbicideTotal(newState);
        return newState;
    },
    [types.SET_COTTON_IMPROVED_QUALITY_FROM_LATE_SEASON_WEED_CONTROL](state, action) {
        let newState = Object.assign({}, state);
        if (action.data !== null) {
            newState.improvedQualityFromLateSeasonWeedControl = action.data;
        } else {
            newState.improvedQualityFromLateSeasonWeedControl = 0;
        }
        newState.totalHerbicide = herbicideTotal(newState);
        return newState;
    },
    [types.SET_COTTON_HERBICIDE_RESISTANCE_MANAGEMENT](state, action) {
        let newState = Object.assign({}, state);
        if (action.data !== null) {
            newState.herbicideResistanceManagement = action.data;
        } else {
            newState.herbicideResistanceManagement = 0;
        }
        newState.totalHerbicide = herbicideTotal(newState);
        return newState;
    },
    [types.AG_CALC_CLEAR_COTTON](state) {
        let newState = Object.assign({}, state);
        newState.lowerUseRate = 0;
        newState.reliableActivation = 0;
        newState.extendedResidualControl = 0;
        newState.improvedQualityFromLateSeasonWeedControl = 0;
        newState.herbicideResistanceManagement = 0;
        newState.totalHerbicide = herbicideTotal(newState);
        return newState;
    },

    [types.SET_COTTON_USE_BAYER_SEED](state, action) {
        let newState = Object.assign({}, state);
        newState.useBayerSeed = action.data;
        return newState;
    },

    [types.SET_COTTON_PRIMARY_SEED](state, action) {
        let newState = Object.assign({}, state);
        newState.primarySeed = action.data;
        return newState;
    },

    [types.RESET_COTTON](state) {
        let newState = Object.assign({}, state);
        newState = DEFAULT_COTTON;
        return newState;
    },
});
