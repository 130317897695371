import createReducer from "../../lib/createReducer";
import * as types from "../actions/types";
import { Competitors, DEV_PREFILL } from "GSAT/Constants";

export const agCalcCompetitor = createReducer(
    {
        competitor: DEV_PREFILL ? Competitors.BAYER : null,
    },
    {
        [types.SET_COMPARISON_COMPETITOR](state, action) {
            let newState = Object.assign({}, state);
            newState.competitor = action.competitor;
            return newState;
        },
        [types.RESET_COMPETITOR](state) {
            let newState = Object.assign({}, state);
            newState.competitor = DEV_PREFILL ? Competitors.BAYER : null;
            return newState;
        },
    },
);
