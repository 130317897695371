import { capitalizeFirstLetter } from "lib/Utils";

export function setStateStream(state, action) {
    const newState = Object.assign({}, state);
    const { data, num_requested, num_header } = action;

    let header = [];
    let stream_data = [];
    let has_more = false;
    let last_post_id = null;

    if (data && data !== null) {
        let streamData = [].concat(data);

        if (num_header > 0) {
            header = data.slice(0, num_header);
            streamData = data.slice(num_header);
        } else {
            header = newState.stateStream.header;
        }
        stream_data = newState.stateStream.data.concat(streamData);
        last_post_id = data.length > 0 ? data[data.length - 1].post_id : 0;
    }

    if (num_requested && num_requested !== null) {
        has_more = data.length === num_requested;
    }

    newState.stateStream = {
        header: header,
        data: stream_data,
        hasMore: has_more,
        lastPostID: last_post_id,
    };

    return newState;
}

export function setPlaylists(state, action) {
    const newState = Object.assign({}, state);
    const { playlist_array } = action;

    let newPlaylistArray = [].concat(newState.playlists.data);

    if (playlist_array && playlist_array !== null) {
        playlist_array.forEach((playlist) => {
            const { playlist_category_name, playlist_data } = playlist;
            const categoryTitle = capitalizeFirstLetter(playlist_category_name ?? "");
            newPlaylistArray.push({ category: categoryTitle, data: playlist_data });
        });
    } else {
        newPlaylistArray = [];
    }

    newState.playlists.data = newPlaylistArray;

    return newState;
}

export function setPlaylistCategories(state, action) {
    const newState = Object.assign({}, state);
    const { data } = action;

    let categories = [];

    if (data && data !== null) {
        categories = [].concat(data);
    }

    newState.playlists.categories = categories;

    return newState;
}

export function setContentDetails(state, action) {
    const newState = Object.assign({}, state);
    const { data } = action;

    if (data && data !== null) {
        newState.details.data = data;
    } else {
        newState.details.data = null;
    }

    return newState;
}

export function setRelatedContent(state, action) {
    const newState = Object.assign({}, state);
    const { data } = action;

    if (data && data !== null) {
        newState.details.related = data;
    } else {
        newState.details.related = [];
    }

    return newState;
}
