import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { connect } from "react-redux";

import Row from "components/Flexbox/Row";
import Column from "components/Flexbox/Column";
import SidebarMenu from "Menu/Menu";
import FullScreenSearch from "Search/FullScreenSearch";
import BASFLogo from "assets/BASF-Logo-White.svg";

import MyAccountPopup from "./internal/AccountPopup";
import Users from "./assets/Users.svg";
import Search from "./assets/Search.svg";
import Menu from "./assets/Menu.svg";
import AdminTools from "./assets/AdminTools.svg";
import "./TopBar.scss";

function TopBar({ iPhoneSEMql = false, userGroupID = null }) {
    const history = useHistory();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    const popupRef = useRef();

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                setIsSearchOpen(false);
            }
        };
        window.addEventListener("keydown", handleEsc);

        return () => {
            window.removeEventListener("keydown", handleEsc);
        };
    }, []);

    const toggleSearch = () => {
        setIsSearchOpen(!isSearchOpen);
        setIsMenuOpen(false);
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        setIsSearchOpen(false);
    };

    return (
        <Column className="main__top-bar-container">
            <Row className="main__top-bar-row" justifyContent="center">
                <Row className="top-bar__content" justifyContent="space-between" alignItems="center">
                    <Row className="top-bar__content--users">
                        <Popup
                            ref={popupRef}
                            trigger={() => <TopBarButton image={Users} />}
                            position={["bottom center", "bottom left"]}
                        >
                            <MyAccountPopup closePopup={()=>{popupRef.current.close();}} />
                        </Popup>
                        {(userGroupID === 1 ||
                            userGroupID === 2 ||
                            userGroupID === 3 ||
                            userGroupID === 5 ||
                            userGroupID === 9) && (
                            <TopBarButton
                                image={AdminTools}
                                onClick={(e) => {
                                    let location = window.location.origin;
                                    if (location.includes(".linxsystems.com")) {
                                        location = location.replace("gslv3", "gsl");
                                    } else {
                                        /** TODO: change https://www.growsmartlive.com to https://internal.growsmartlive.com */
                                        if (location.includes('alpha.growsmartlive.com')){
                                           location = 'https://alpha.internal.growsmartlive.com'
                                        } else if (location.includes('beta.growsmartlive.com')) {
                                            location = 'https://beta.internal.growsmartlive.com'
                                        } else if (location.includes('www.growsmartlive.com')){
                                            location = "https://www.internal.growsmartlive.com";
                                        }
                                    }

                                    if (e.ctrlKey || e.metaKey) {
                                        window.open(location, "_blank");
                                    } else {
                                        window.location.assign(location);
                                    }
                                }}
                                className={`top-bar__content--editor${iPhoneSEMql ? "--se_mql" : ""}`}
                            />
                        )}
                    </Row>
                    <img
                        src={BASFLogo}
                        className={`top-bar__content--logo${iPhoneSEMql ? "--se_mql" : ""}`}
                        alt="BASF Logo"
                        onClick={() => history.push("/")}
                    />
                    <Row>
                        <TopBarButton image={Search} onClick={toggleSearch} />
                        <TopBarButton
                            image={Menu}
                            className={`top-bar__content--hamburger-menu${iPhoneSEMql ? "--se_mql" : ""}`}
                            onClick={toggleMenu}
                        />
                    </Row>
                </Row>
            </Row>
            <SidebarMenu isOpen={isMenuOpen} handleStateChange={(state) => setIsMenuOpen(state)} />
            {isSearchOpen && <FullScreenSearch closeSearch={() => setIsSearchOpen(false)} />}
        </Column>
    );
}

const TopBarButton = React.forwardRef(({ open, image, className = "", onClick = () => {}, ...props }, ref) => {
    return (
        <div ref={ref} className={`top-bar__menu-button ${className}`} onClick={onClick}>
            <img src={image} alt="menu button" />
        </div>
    );
});

function mapStateToProps(state) {
    return {
        isResponsive: state.responsive.isResponsive,
        iPhoneSEMql: state.responsive.iPhoneSEMql,
        userGroupID: state.user.groupID,
    };
}

export default connect(mapStateToProps, null, null)(TopBar);
