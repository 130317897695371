import React from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as LockIcon } from "Menu/assets/lock.svg";
import "./MenuItem.scss";

function MenuItem({ icon, name, link = "", href = "", locked = false, closeMenu }) {

    const renderLockIcon = (props) => {
        return <LockIcon {...props} />
    }

    if(href === "") {
        return (
            <NavLink
                to={link}
                exact
                className={`menu-item${locked ? '--locked' : ''}`}
                activeClassName={`menu-item--active`}
                onClick={closeMenu}
            >
                <div className="menu-item__icon-container">{icon({className: `menu-item__icon${locked ? '--locked' : ''}`})}</div>
                <p className="menu-item__name">{name}</p>
                {locked && renderLockIcon({className: 'menu-item__icon-lock'})}
            </NavLink>
        );
    } else {
        return (
            <a
                href={href}
                className={`menu-item${locked ? '--locked' : ''}`}
                activeClassName={`menu-item--active`}
                onClick={closeMenu}
            >
                <div className="menu-item__icon-container">{icon({className: `menu-item__icon${locked ? '--locked' : ''}`})}</div>
                <p className="menu-item__name">{name}</p>
                {locked && renderLockIcon({className: 'menu-item__icon-lock'})}
            </a>
        )
    }
}

export default MenuItem;
