import createReducer from "lib/createReducer";
import * as types from "LRF/actions/types";

export const lrf = createReducer(
    {
        maps: {},
        locationData: {},
    },
    {
        [types.SET_SPATIAL_LRF_MAPS](state, action) {
            let newState = Object.assign({}, state);
            newState.maps = action.maps;
            return newState;
        },

        [types.SET_LOCATION_SPECIFIC_LRF](state, action) {
            let newState = Object.assign({}, state);
            newState.locationData = action.data;
            return newState;
        },
    },
);
