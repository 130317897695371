import { executeBasicNetworkCall } from "lib/Utils";
import { signIn } from "Authentication/actions/auth";

export function request2FATokenForThisDevice(
    username,
    password,
    udid,
    requestType = "email",
    success = () => {},
    failure = () => {},
    email = null,
) {
    return (dispatch, getState) => {
        const json = {
            fingerprint: udid,
            type: requestType,
        };

        if (email !== null) {
            json.email = email;
        } else {
            json.username = username;
            json.password = password;
        }

        executeBasicNetworkCall(
            "create_two_factor_authenticate_and_send",
            JSON.stringify(json),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        failure(x.message, requestType);
                    } else {
                        success(x.message, requestType);
                    }
                }
            },
            (error) => {},
        );
    };
}

export function validate2FAToken(udid, token, username, password, success = () => {}, failure = () => {}, loginParams = null) {
    return (dispatch, getState) => {
        const json = {
            fingerprint: udid,
            token,
        };

        executeBasicNetworkCall(
            "create_trusted_device",
            JSON.stringify(json),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        failure(x.message);
                    } else {
                        if (loginParams === null) {
                            this.login(username, password, udid);
                            success();
                        } else {
                            dispatch(signIn(loginParams, success));
                        }
                    }
                }
            },
            (error) => {},
        );
    };
}
