import { setStateStream, setPlaylists, setPlaylistCategories, setContentDetails, setRelatedContent } from "ContentStream";
import createReducer from "lib/createReducer";
import * as types from "News/actions/types";

export const news = createReducer(
    {
        stateStream: {
            header: [],
            data: [],
            hasMore: false,
            lastPostID: null,
        },
        playlists: {
            categories: [],
            data: [],
        },
        details: {
            data: null,
            related: [],
        },
    },
    {
        [types.SET_NEWS_STATE_STREAM](state, action) {
            return setStateStream(state, action);
        },

        [types.SET_NEWS_PLAYLISTS](state, action) {
            return setPlaylists(state, action);
        },

        [types.SET_NEWS_PLAYLIST_CATEGORIES](state, action) {
            return setPlaylistCategories(state, action);
        },

        [types.SET_NEWS_DETAILS](state, action) {
            return setContentDetails(state, action);
        },

        [types.SET_RELATED_NEWS](state, action) {
            return setRelatedContent(state, action);
        },
    },
);
