import { HOST } from "Constants";

export function getURLForEndpointName(name, urls) {
    for (let i = 0; i < urls.length; i++) {
        const endpoint = urls[i];
        if (endpoint.name === name) {
            return endpoint.url;
        }
    }
}

export function executeBasicNetworkCall(
    endpoint,
    params,
    contentType,
    httpMethod = "POST",
    getState,
    onReady = () => {},
    onError = () => {},
    debug = false,
    onBadStatus = () => {},
    overrideURL,
    withCredentials = true,
) {
    if (debug) {
        // eslint-disable-next-line no-console
        console.log(endpoint);
    }

    const endpoints = getState().endpoints;
    if (endpoints === null && overrideURL === undefined) {
        return;
    }
    if ((endpoint === undefined || endpoint === null) && overrideURL === undefined) {
        return;
    }

    let URL = overrideURL;

    if (URL === undefined || URL === null) {
        // BUILD THE API URL
        const API_VERSION = endpoints.current_api_version;
        const ENDPOINT = getURLForEndpointName(endpoint, endpoints.urls);
        URL = `${HOST}/API/${API_VERSION}${ENDPOINT}`;
    }

    if (debug) {
        // eslint-disable-next-line no-console
        console.log(URL);
    }

    const reqq = new XMLHttpRequest();
    reqq.onreadystatechange = (e) => {
        try {
            if (reqq.readyState !== 4) {
                return;
            }
            if (debug) {
                // eslint-disable-next-line no-console
                console.log(reqq);
            }
            if (reqq.status !== 200) {
                onBadStatus(reqq.status);
                // bugsnagClient.notify(new Error(`endpoint returned status ${reqq.status}`));
            } else {
                onReady(reqq);
            }
        } catch (error) {
            onError(error);
            // bugsnagClient.notify(error);
        }
    };
    reqq.open(httpMethod, URL);
    reqq.setRequestHeader("Content-Type", contentType);
    reqq.withCredentials = withCredentials;
    reqq.send(params);
}

export function numberWithCommas(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function nFormatter(num = 0, digits = 1) {
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const item = lookup
        .slice()
        .reverse()
        .find(function (item) {
            return num >= item.value;
        });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function createEnum(values) {
    const enumObject = {};
    for (const val of values) {
        enumObject[val] = val;
    }
    return Object.freeze(enumObject);
}

export function validateEmailAddress(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function validatePhoneFormat(phone) {
    const re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
    return re.test(String(phone));
}

export function validateZipCodeFormat(zip) {
    const re = /(^\d{5}$)/;
    return re.test(String(zip));
}

export function celsiusToFahrenheit(cel, decimalPlaces = 2) {
    return ((cel * 1.8) + 32).toFixed(decimalPlaces);
}

export function millimetersToInches(mm, decimalPlaces = 2) {
    return (mm / 25.4).toFixed(decimalPlaces);
}

export function getHighlightedText(text = '', highlight = '', styling = { color: '#00a300', fontWeight: 'bold' }) {
    // Split on highlight term and include term into parts, ignore case
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return <span> { parts.map((part, i) => 
        <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? styling : {} }>
            { part }
        </span>)
    } </span>;
}
