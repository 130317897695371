import { executeBasicNetworkCall } from "lib/Utils";
import * as types from "Authentication/actions/types";

export function setZipCode(zipcode = null) {
    return {
        type: types.SET_ZIP_CODE,
        zipcode,
    };
}

export function setUserState(userState = null, userStateID = null) {
    return {
        type: types.SET_USER_STATE,
        userState,
        userStateID,
    };
}

export function setUserCounty(userCounty = null, userCountyID = null) {
    return {
        type: types.SET_USER_COUNTY,
        userCounty,
        userCountyID,
    };
}

export function getCountyListByState(state_id, successCallback = () => {}, errorCallback = () => {}) {
    return (dispatch, getState) => {
        const json = JSON.stringify({ state_id: state_id });

        executeBasicNetworkCall("get_county_list_by_state", json, "application/json", "POST", getState, (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                if (x.error) {
                    errorCallback(x.message);
                } else {
                    successCallback(x.data.counties);
                }
            }
        });
    };
}

export function setZipAndStateAndCounty(
    zip_code,
    state_name,
    state_id,
    county_name,
    county_id,
    successCallback = () => {},
    errorCallback = () => {},
) {
    return (dispatch, getState) => {
        const json = JSON.stringify({ zip_code: zip_code, state_id: state_id, county_id: county_id });

        executeBasicNetworkCall("set_zip_code_and_state", json, "application/json", "POST", getState, (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                if (x.error) {
                    errorCallback(x.message);
                } else {
                    successCallback(x.message);
                    dispatch(setZipCode(zip_code));
                    dispatch(setUserState(state_name, state_id));
                    dispatch(setUserCounty(county_name, county_id));
                }
            }
        });
    };
}

export function validateZipCode(zip_code, successCallback = () => {}, errorCallback = () => {}) {
    return (dispatch, getState) => {
        const json = JSON.stringify({ zip_code: zip_code });

        executeBasicNetworkCall("validate_zip_code", json, "application/json", "POST", getState, (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                if (x.error) {
                    errorCallback(x.message);
                } else {
                    successCallback(x.data.state, x.data.county);
                }
            }
        });
    };
}

export function setOpenUpgradeModal(openUpgradeModal = false) {
    return {
        type: types.SET_OPEN_UPGRADE_MODAL,
        data: openUpgradeModal,
    }
}

export function getCropsDropdownList(successCallback = () => {}, errorCallback = () => {}) {
    return (dispatch, getState) => {

        executeBasicNetworkCall("get_crops_dropdown_list", null, "application/json", "POST", getState, (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                if (x.error) {
                    errorCallback(x.message);
                } else {
                    successCallback(x.data);
                }
            }
        });
    };
}

export function upgradeToCustomer(
    street_address,
    city,
    state_id,
    zip_code,
    phone_number,
    crop_ids = [],
    circle_name,
    successCallback = () => {},
    errorCallback = () => {},
) {
    return (dispatch, getState) => {
        const json = JSON.stringify({
            state_id: state_id,
            city: city,
            zip_code: zip_code,
            street_address: street_address,
            phone_number: phone_number,
            crop_ids: crop_ids,
            circle_name: circle_name,
        });

        executeBasicNetworkCall("upgrade_to_customer", json, "application/json", "POST", getState, (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                console.log(x);
                if (x.error) {
                    errorCallback(x.message);
                } else {
                    successCallback(x.message);
                }
            }
        });
    };
}
