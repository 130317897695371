import Row from "components/Flexbox/Row";
import "./ContentPreviewDefault.scss";

export default function ContentPreviewInfo({
    id = "",
    title = "Title",
    author = "GrowSmart Admin",
    containerClassName = "",
    onClick = () => {},
}) {
    return (
        <div className={`content-preview-default ${containerClassName}`} onClick={onClick}>
            <Row className="content-preview-default__metadata-container" alignItems="center">
                <p className="content-preview-default__title">{title}</p>
                <Row alignItems="center">
                    <div className="content-preview-default__vertical-divider" />
                    <p className="content-preview-default__author">
                        By
                        <br />
                        {author}
                    </p>
                </Row>
            </Row>
        </div>
    );
}
