import createReducer from "lib/createReducer";
import { GSAT_RESPONSIVE_WIDTH, MOBILE_MAX_WIDTH, PADDED_MIN_WIDTH, PHONE_WIDTH } from "../index";
import * as types from "./types";

export const responsive = createReducer(
    {
        isResponsive: window.outerWidth < MOBILE_MAX_WIDTH,
        isPadded: window.outerWidth > PADDED_MIN_WIDTH,
        iPhoneSEMql: window.outerWidth < PHONE_WIDTH,
        isGsatResponsive: window.outerWidth < GSAT_RESPONSIVE_WIDTH,
    },
    {
        [types.SET_IS_RESPONSIVE](state, action) {
            let newState = Object.assign({}, state);
            newState.isResponsive = action.isResponsive;
            return newState;
        },

        [types.SET_IS_PADDED](state, action) {
            let newState = Object.assign({}, state);
            newState.isPadded = action.isPadded;
            return newState;
        },

        [types.SET_IPHONE_SE_MQL](state, action) {
            let newState = Object.assign({}, state);
            newState.iPhoneSEMql = action.iPhoneSEMql;
            return newState;
        },

        [types.SET_GSAT_RESPONSIVE_MQL](state, action) {
            let newState = Object.assign({}, state);
            newState.isGsatResponsive = action.isGsatResponsive;
            return newState;
        },
    },
);
