import createReducer from "../../lib/createReducer";
import * as types from "../actions/types";
import { Competitors, ControlLevel, CornPrimarySeeds, DEV_PREFILL } from "GSAT/Constants";

function cornHerbicideTotal(state) {
    if (state.competitor === Competitors.BAYER) {
        return bayerCornHerbicideTotal(state);
    } else if (state.competitor === Competitors.SYNGENTA) {
        return syngentaCornHerbicideTotal(state);
    } else {
        return 0;
    }
}

function bayerCornHerbicideTotal(state) {
    // what herbicide sliders are shown?
    if (state.applicationMethod === 0) {
        //prepost
        if (state.controlLevel === ControlLevel.Good || state.controlLevel === ControlLevel.Better) {
            return state.lowerUseRate + state.reliableActivation + state.cropSafety;
        } else if (state.controlLevel === ControlLevel.Best) {
            return state.lowerUseRate + state.flexibleApplication + state.reliableActivation + state.cropSafety;
        }
    } else if (state.applicationMethod === 1) {
        //pre-only
        if (state.controlLevel === ControlLevel.Good) {
            return state.lowerUseRate + state.reliableActivation + state.cropSafety + state.broadSpectrumResidualControl;
        } else if (state.controlLevel === ControlLevel.Better) {
            return state.lowerUseRate + state.flexibleApplication + state.reliableActivation + state.cropSafety;
        } else if (state.controlLevel === ControlLevel.Best) {
            return state.lowerUseRate + state.cropSafety + state.broadSpectrumResidualControl;
        }
    } else if (state.applicationMethod === 2) {
        //post-only
        if (state.controlLevel === ControlLevel.Good) {
            return state.flexibleApplication + state.reliableActivation;
        } else if (state.controlLevel === ControlLevel.Better) {
            return state.flexibleApplication + state.reliableActivation + state.cropSafety;
        } else if (state.controlLevel === ControlLevel.Best) {
            return state.lowerUseRate + state.flexibleApplication + state.reliableActivation + state.cropSafety;
        }
    } else {
        return 0;
    }
}

function syngentaCornHerbicideTotal(state) {
    // what herbicide sliders are shown?
    if (state.applicationMethod === 0) {
        //prepost
        if (state.controlLevel === ControlLevel.Good) {
            return state.lowerUseRate + state.reliableActivation + state.broadSpectrumKnockdown;
        } else if (state.controlLevel === ControlLevel.Better || state.controlLevel === ControlLevel.Best) {
            return state.lowerUseRate + state.reliableActivation + state.resistanceManagement;
        }
    } else if (state.applicationMethod === 1) {
        //pre-only
        if (state.controlLevel === ControlLevel.Good) {
            return state.lowerUseRate + state.reliableActivation + state.resistanceManagement;
        } else if (state.controlLevel === ControlLevel.Better) {
            return state.lowerUseRate + state.reliableActivation;
        } else if (state.controlLevel === ControlLevel.Best) {
            return state.lowerUseRate + state.resistanceManagement;
        }
    } else if (state.applicationMethod === 2) {
        //post-only
        if (state.controlLevel === ControlLevel.Good) {
            return state.lowerUseRate + state.reliableActivation + state.broadSpectrumKnockdown;
        } else if (state.controlLevel === ControlLevel.Better) {
            return state.lowerUseRate + state.reliableActivation;
        } else if (state.controlLevel === ControlLevel.Best) {
            return state.lowerUseRate;
        }
    } else {
        return 0;
    }
}

const DEFAULT_CORN = {
    competitor: DEV_PREFILL ? Competitors.BAYER : null,
    cornSelected: DEV_PREFILL,
    useBayerSeed: DEV_PREFILL,
    primarySeed: DEV_PREFILL ? CornPrimarySeeds.Dekalb : null,

    lowerUseRate: 0,
    flexibleApplication: 0,
    reliableActivation: 0,
    cropSafety: 0,
    broadSpectrumResidualControl: 0,
    broadSpectrumKnockdown: 0,
    resistanceManagement: 0,

    totalHerbicide: 0,

    applicationMethod: DEV_PREFILL ? 0 : null,
    controlLevel: DEV_PREFILL ? ControlLevel.Good : null,
    cornAcres: DEV_PREFILL ? 1500 : "",
    cornMarketPrice: 0,
};

export const agCalcCornHerbicide = createReducer(DEFAULT_CORN, {
    [types.SET_COMPARISON_COMPETITOR](state, action) {
        let newState = Object.assign({}, state);
        newState.competitor = action.competitor;
        return newState;
    },

    [types.SET_CORN_SELECTED](state, action) {
        let newState = Object.assign({}, state);
        newState.cornSelected = action.data;
        return newState;
    },

    [types.SET_CORN_ACRES](state, action) {
        let newState = Object.assign({}, state);
        newState.cornAcres = action.data;
        return newState;
    },

    [types.SET_CORN_MARKET_PRICE](state, action) {
        let newState = Object.assign({}, state);
        newState.cornMarketPrice = action.data;
        return newState;
    },

    [types.SET_CORN_CONTROL_LEVEL](state, action) {
        let newState = Object.assign({}, state);
        newState.controlLevel = action.data;
        newState.totalHerbicide = cornHerbicideTotal(newState);
        return newState;
    },

    [types.SET_CORN_APPLICATION_METHOD](state, action) {
        let newState = Object.assign({}, state);
        newState.applicationMethod = action.data;
        newState.totalHerbicide = cornHerbicideTotal(newState);
        return newState;
    },

    [types.SET_CORN_LOWER_USE_RATE](state, action) {
        let newState = Object.assign({}, state);
        if (action.data !== null) {
            newState.lowerUseRate = action.data;
        } else {
            newState.lowerUseRate = 0;
        }
        newState.totalHerbicide = cornHerbicideTotal(newState);
        return newState;
    },

    [types.SET_CORN_FLEXIBLE_APPLICATION](state, action) {
        let newState = Object.assign({}, state);
        if (action.data !== null) {
            newState.flexibleApplication = action.data;
        } else {
            newState.flexibleApplication = 0;
        }
        newState.totalHerbicide = cornHerbicideTotal(newState);
        return newState;
    },

    [types.SET_CORN_RELIABLE_ACTIVATION](state, action) {
        let newState = Object.assign({}, state);
        if (action.data !== null) {
            newState.reliableActivation = action.data;
        } else {
            newState.reliableActivation = 0;
        }
        newState.totalHerbicide = cornHerbicideTotal(newState);
        return newState;
    },

    [types.SET_CORN_CROP_SAFETY](state, action) {
        let newState = Object.assign({}, state);
        if (action.data !== null) {
            newState.cropSafety = action.data;
        } else {
            newState.cropSafety = 0;
        }
        newState.totalHerbicide = cornHerbicideTotal(newState);
        return newState;
    },

    [types.SET_CORN_BROAD_SPECTRUM_RESIDUAL_CONTROL](state, action) {
        let newState = Object.assign({}, state);
        if (action.data !== null) {
            newState.broadSpectrumResidualControl = action.data;
        } else {
            newState.broadSpectrumResidualControl = 0;
        }
        newState.totalHerbicide = cornHerbicideTotal(newState);
        return newState;
    },
    [types.SET_CORN_BROAD_SPECTRUM_KNOCKDOWN](state, action) {
        let newState = Object.assign({}, state);
        if (action.data !== null) {
            newState.broadSpectrumKnockdown = action.data;
        } else {
            newState.broadSpectrumKnockdown = 0;
        }
        newState.totalHerbicide = cornHerbicideTotal(newState);
        return newState;
    },
    [types.SET_CORN_RESISTANCE_MANAGEMENT](state, action) {
        let newState = Object.assign({}, state);
        if (action.data !== null) {
            newState.resistanceManagement = action.data;
        } else {
            newState.resistanceManagement = 0;
        }
        newState.totalHerbicide = cornHerbicideTotal(newState);
        return newState;
    },

    [types.AG_CALC_CLEAR_CORN](state) {
        let newState = Object.assign({}, state);
        newState.lowerUseRate = 0;
        newState.flexibleApplication = 0;
        newState.reliableActivation = 0;
        newState.cropSafety = 0;
        newState.broadSpectrumResidualControl = 0;
        newState.totalHerbicide = cornHerbicideTotal(newState);
        return newState;
    },

    [types.SET_CORN_USE_BAYER_SEED](state, action) {
        let newState = Object.assign({}, state);
        newState.useBayerSeed = action.data;
        return newState;
    },
    [types.SET_CORN_PRIMARY_SEED](state, action) {
        let newState = Object.assign({}, state);
        newState.primarySeed = action.data;
        return newState;
    },
    [types.RESET_CORN](state) {
        let newState = Object.assign({}, state);
        newState = DEFAULT_CORN;
        return newState;
    },
});

const DEFAULT_CORN_FUNGICIDE = {
    cornFungicideSelected: true,
    cornFungicideBuPerAcre: 0,
};

export const agCalcCornFungicide = createReducer(DEFAULT_CORN_FUNGICIDE, {
    [types.SET_CORN_FUNGICIDE_SELECTED](state, action) {
        let newState = Object.assign({}, state);
        newState.cornFungicideSelected = action.data;
        return newState;
    },
    [types.SET_CORN_FUNGICIDE_BU_PER_ACRE](state, action) {
        let newState = Object.assign({}, state);
        newState.cornFungicideBuPerAcre = action.data;
        return newState;
    },
    [types.RESET_CORN_FUNGICIDE](state) {
        let newState = Object.assign({}, state);
        newState = DEFAULT_CORN_FUNGICIDE;
        return newState;
    },
});
