import { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ResponsiveActions from "./redux/actions";
import { useMedia } from "lib/hooks";

export const MOBILE_MAX_WIDTH = 800; // in pixels
export const PADDED_MIN_WIDTH = 1225; // in pixels
export const PHONE_WIDTH = 500; // in pixels
export const GSAT_RESPONSIVE_WIDTH = 1000; // in pixels
const Actions = Object.assign({}, ResponsiveActions);

function ResponsiveManager({ setIsResponsive, setIsPadded, setIPhoneSEMql, setGSATMobileMql }) {
    const mql = useMedia([`(min-width: ${MOBILE_MAX_WIDTH}px)`], [false], true);
    const isPadded = useMedia([`(max-width: ${PADDED_MIN_WIDTH}px)`], [false], true);
    const iPhoneSEMql = useMedia([`(min-width: ${PHONE_WIDTH}px)`], [false], true);
    const isGsatResponsive = useMedia([`(min-width: ${GSAT_RESPONSIVE_WIDTH}px)`], [false], true);

    useEffect(() => {
        setIsResponsive(mql);
    }, [mql, setIsResponsive]);

    useEffect(() => {
        setIsPadded(isPadded);
    }, [isPadded, setIsPadded]);

    useEffect(() => {
        setIPhoneSEMql(iPhoneSEMql);
    }, [iPhoneSEMql, setIPhoneSEMql]);

    useEffect(() => {
        setGSATMobileMql(isGsatResponsive);
    }, [isGsatResponsive, setGSATMobileMql]);

    return null;
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

export default connect(null, mapDispatchToProps, null)(ResponsiveManager);
