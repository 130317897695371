import createReducer from "lib/createReducer";
import * as types from "redux/actions/types";

export const messages = createReducer(
    {
        error_message: "",
        success_message: "",
    },
    {
        [types.SET_ERROR](state, action) {
            let newState = Object.assign({}, state);
            if (action.message !== null) {
                newState.error_message = action.message;
            } else {
                newState.error_message = "";
            }
            return newState;
        },
        [types.SET_SUCCESS](state, action) {
            let newState = Object.assign({}, state);
            if (action.message !== null) {
                newState.success_message = action.message;
            } else {
                newState.success_message = "";
            }
            return newState;
        },
    },
);
