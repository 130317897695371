import React, { useState } from "react";

function MyErrorBoundary({ children }) {
    // const [hasError, setHasError] = useState(false);
    const [hasError] = useState(false);

    if (hasError) {
        return <h1>Something went wrong. Please refresh the page.</h1>;
    } else {
        return children;
    }
}

export default MyErrorBoundary;
