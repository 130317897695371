import ContentStream from "ContentStream/screens";
import {
    getContentDetails,
    getPlaylists,
    getPlaylistCategories,
    getPlaylistStream,
    getStateStream,
    getRelatedContent,
    setUserViewPost,
    setUserLikePost,
    setUserUnlikePost,
} from "ContentStream/lib/actions";
import { CONTENT_TYPES, PLAYLIST_CATEGORY_TYPES, PLAYLIST_CATEGORIES } from "ContentStream/lib/Constants";
import { useDataInitialize, useStateStreamInitialize, usePlaylistsInitialize } from "ContentStream/lib/hooks";
import { setStateStream, setPlaylists, setPlaylistCategories, setContentDetails, setRelatedContent } from "ContentStream/lib/reducers";

export {
    // constants
    CONTENT_TYPES,
    PLAYLIST_CATEGORY_TYPES,
    PLAYLIST_CATEGORIES,
    // actions
    getContentDetails,
    getPlaylists,
    getPlaylistCategories,
    getPlaylistStream,
    getStateStream,
    getRelatedContent,
    setUserViewPost,
    setUserLikePost,
    setUserUnlikePost,
    // hooks
    useDataInitialize,
    useStateStreamInitialize,
    usePlaylistsInitialize,
    // reducers
    setStateStream,
    setPlaylists,
    setPlaylistCategories,
    setContentDetails,
    setRelatedContent,
};
export default ContentStream;
