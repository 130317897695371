import * as types from "../actions/types";

export function resetSoybean() {
    return {
        type: types.RESET_SOYBEAN,
    };
}

export function resetSoybeanFungicide() {
    return {
        type: types.RESET_SOYBEAN_FUNGICIDE,
    };
}

export function setSoybeanSelected(data) {
    return {
        type: types.SET_SOYBEAN_SELECTED,
        data: data,
    };
}

export function setSoybeanUseBayerSeed(data) {
    return {
        type: types.SET_SOYBEAN_USE_BAYER_SEED,
        data: data,
    };
}

export function setSoybeanPrimarySeed(data) {
    return {
        type: types.SET_SOYBEAN_PRIMARY_SEED,
        data: data,
    };
}

export function setSoybeanTills(tills = false) {
    return {
        type: types.SET_TILLS,
        data: tills,
    };
}

export function setUseDicamba(data) {
    return {
        type: types.SET_USE_DICAMBA,
        data: data,
    };
}

export function setSoybeanAcres(data) {
    return {
        type: types.SET_SOYBEAN_ACRES,
        data: data,
    };
}

export function setSoybeanMarketPrice(data) {
    return {
        type: types.SET_SOYBEAN_MARKET_PRICE,
        data: data,
    };
}

export function setSoybeanControlLevel(data) {
    return {
        type: types.SET_SOYBEAN_CONTROL_LEVEL,
        data: data,
    };
}
export function setSoybeanTraitPlatform(data) {
    return {
        type: types.SET_SOYBEAN_TRAIT_PLATFORM,
        data: data,
    };
}

export function setSoybeanLowerUseRate(data) {
    return {
        type: types.SET_SOYBEAN_LOWER_USE_RATE,
        data,
    };
}

export function setSoybeanAmaranthAdvantage(data) {
    return {
        type: types.SET_SOYBEAN_AMARANTH_ADVANTAGE,
        data,
    };
}

export function setSoybeanBurndownAdvantage(data) {
    return {
        type: types.SET_SOYBEAN_BURNDOWN_ADVANTAGE,
        data,
    };
}

export function setSoybeanExtendedResidualControl(data) {
    return {
        type: types.SET_SOYBEAN_EXTENDED_RESIDUAL_CONTROL,
        data,
    };
}

export function setSoybeanReliableActivation(data) {
    return {
        type: types.SET_SOYBEAN_RELIABLE_ACTIVATION,
        data,
    };
}

export function setSoybeanCropSafety(data) {
    return {
        type: types.SET_SOYBEAN_CROP_SAFETY,
        data,
    };
}

export function setSoybeanLateSeasonWeedBreaks(data) {
    return {
        type: types.SET_SOYBEAN_LATE_SEASON_WEED_BREAKS,
        data,
    };
}

export function setSoybeanCobraInjury(data) {
    return {
        type: types.SET_SOYBEAN_COBRA_INJURY,
        data,
    };
}

export function setSoybeanFungicideSelected(data) {
    return {
        type: types.SET_SOYBEAN_FUNGICIDE_SELECTED,
        data: data,
    };
}

export function setSoybeanFungicideBuPerAcre(data) {
    return {
        type: types.SET_SOYBEAN_FUNGICIDE_BU_PER_ACRE,
        data: data,
    };
}

export function agCalcClearSoybean() {
    return {
        type: types.AG_CALC_CLEAR_SOBYEAN,
    };
}
