import * as types from "./types";
import { HOST } from "Constants.js";
// import { recordAnalytics } from "lib/analytics";

// Get all API URL endpoints
export function getEndpoints() {
    return (dispatch, getState) => {
        const url = HOST + "/API/v3/validate";

        const reqq = new XMLHttpRequest();
        reqq.onreadystatechange = (e) => {
            try {
                if (reqq.readyState !== 4) {
                    return;
                }
                //
                //
                if (reqq.status === 200) {
                    if (reqq.responseText != null) {
                        const x = JSON.parse(reqq.responseText);
                        if (x.error) {
                            // dispatch(setEndpointURLS(null));
                        } else {
                            if (x.data) {
                                const info = x.data;
                                dispatch(setEndpointURLS(info));

                                if (!process.env.CI && !window.Cypress) {
                                    // don't record traffic from CircleCI!

                                    if (document.referrer.indexOf("localhost") === -1) {
                                        // don't record local traffic

                                        // let pathname = window.location.pathname;

                                        // // don't record security tokens (huge security problem!!!)
                                        // pathname = pathname.replace(
                                        //     /[0-9a-f]{8}-?[0-9a-f]{4}-?[0-9a-f]{4}-?[0-9a-f]{4}-?[0-9a-f]{12}/g,
                                        //     ":token",
                                        // );

                                        if (document.referrer && document.referrer.length > 0) {
                                            // recordAnalytics(false, "Traffic", "Referral", document.referrer, pathname);
                                        } else {
                                            // recordAnalytics(false, "Traffic", "Direct", pathname);
                                        }
                                    }
                                }
                            }
                        }
                    }
                } else {
                    // dispatch(setEndpointURLS(null));
                }
            } catch (error) {
                //
                //  MyBugSnag.getInstance().client.notify(error);
                // dispatch(setEndpointURLS(null));
            }
        };
        reqq.open("POST", url);
        reqq.send();
    };
}

function setEndpointURLS(info) {
    return {
        type: types.SET_ENDPOINT_URLS,
        info,
    };
}
