import Column from "../../../components/Flexbox/Column";
import { NavLink } from "react-router-dom";
import "./NavigationBarItem.scss";
import { connect } from "react-redux";
import lockIcon from "ContentArea/internal/NavigationBar/assets/lock.svg";

function NavigationBarItem({ name, image, locked, link, hasNotification, onClick, exact, isResponsive, iPhoneSEMql, setIsSearchOpen }) {

    if (name === "Search") {
        return (
            <div
                onClick={() => {setIsSearchOpen(true)}}
                className={locked ? "nav-bar-item--locked nav-bar-item--selected" : "nav-bar-item nav-bar-item--selected"}
            >
                <Column alignItems="center">
                    <div
                        className={`nav-bar-item__image-container${isResponsive ? "--mobile" : ""} ${iPhoneSEMql ? "nav-bar-item__image-container--se_mql" : ""
                            }`}
                    >
                        {image({ className: isResponsive ? "nav-bar-item__image--mobile" : "nav-bar-item__image" })}
                        {locked && <img src={lockIcon} alt="locked" className={`nav-bar-item__icon-lock${isResponsive ? '--mobile' : ''}`} />}
                    </div>
                    <p className={`nav-bar-item__label ${iPhoneSEMql ? "nav-bar-item__label--se_mql" : ""}`}>{name}</p>
                </Column>

            </div>
        );
    }

    return (
        <NavLink
            to={link}
            exact={exact}
            className={locked ? "nav-bar-item--locked" : "nav-bar-item"}
            activeClassName="nav-bar-item--selected"
        >
            <Column alignItems="center">
                <div
                    className={`nav-bar-item__image-container${isResponsive ? "--mobile" : ""} ${
                        iPhoneSEMql ? "nav-bar-item__image-container--se_mql" : ""
                    }`}
                >
                    {image({ className: isResponsive ? "nav-bar-item__image--mobile" : "nav-bar-item__image" })}
                    {locked && <img src={lockIcon} alt="locked" className={`nav-bar-item__icon-lock${isResponsive ? '--mobile' : ''}`} />}
                </div>
                <p className={`nav-bar-item__label ${iPhoneSEMql ? "nav-bar-item__label--se_mql" : ""}`}>{name}</p>
            </Column>
        </NavLink>
    );
}

function mapStateToProps(state) {
    return {
        isResponsive: state.responsive.isResponsive,
        iPhoneSEMql: state.responsive.iPhoneSEMql,
    };
}

export default connect(mapStateToProps, null, null)(NavigationBarItem);
