export const CONTENT_TYPES = {
    NEWS: "article",
    OBSERVATION: "observation",
    VIDEO: "video",
};

export const PLAYLIST_CATEGORY_TYPES = {
    CROP: "crop",
    PRODUCT_CATEGORY: "product_category",
    PRODUCT: "product",
};

export const PLAYLIST_CATEGORIES = [
    { type: PLAYLIST_CATEGORY_TYPES.CROP, data: ["corn", "soybeans"] },
    { type: PLAYLIST_CATEGORY_TYPES.PRODUCT_CATEGORY, data: ["herbicide", "fungicide"] },
    { type: PLAYLIST_CATEGORY_TYPES.PRODUCT, data: [] },
];
