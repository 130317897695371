import createReducer from "lib/createReducer";
import * as types from "redux/actions/types";

export const endpoints = createReducer(
    {
        current_api_version: null,
        current_app_version: null,
        updated_api_version: null,
        urls: [],
    },
    {
        [types.SET_ENDPOINT_URLS](state, action) {
            let newState = Object.assign({}, state);
            if (action.info !== null) {
                newState.current_api_version = action.info.current_api_version;
                newState.current_app_version = action.info.current_app_version;
                newState.updated_api_version = action.info.updated_api_version;
                newState.urls = action.info.urls;
            } else {
                newState.current_api_version = null;
                newState.current_app_version = null;
                newState.updated_api_version = null;
                newState.urls = [];
            }
            return newState;
        },
    },
);
