import { combineReducers } from "redux";

import * as responsiveReducers from "ResponsiveManager/redux/reducer";
import * as endpointReducers from "./endpoints";
import * as messagesReducers from "./messages";
import gsatReducers from "GSAT/reducers";
import { reducer as burgerMenu } from "redux-burger-menu";
import authReducers from "Authentication/reducers";
import newsReducers from "News/reducers";
import videosReducers from "Videos/reducers";
import observationsReducers from "Observations/reducers";
import lrfReducers from "LRF/reducers";
import searchReducers from "Search/reducers";

export default combineReducers(
    Object.assign(
        responsiveReducers,
        endpointReducers,
        messagesReducers,
        gsatReducers,
        burgerMenu,
        authReducers,
        newsReducers,
        videosReducers,
        observationsReducers,
        lrfReducers,
        searchReducers,
    ),
);
