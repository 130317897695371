import { executeBasicNetworkCall } from "lib/Utils";
import * as types from "Authentication/actions/types";
// import bugsnagClient from "lib/bugsnag";
import { clearNewsContent } from "News/actions/news";
import { clearObservationsContent } from "Observations/actions/observations";
import { clearVideosContent } from "Videos/actions/videos";
import { clearLRF } from "LRF/actions/lrf";
import { clearGSAT } from "GSAT/actions/generic";

export function signIn(
    params,
    callback = () => {
        throw new Error("must supply callback");
    },
    register = () => {},
) {
    return (dispatch, getState) => {
        executeBasicNetworkCall("auth_login", JSON.stringify({ ...params }), "application/json", "POST", getState, (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                if (x.error) {
                    if (x.code === 106) {
                        register(x.email, x.phone_number, params);
                    } else {
                        callback(false, x.data, x.message);
                    }
                } else {
                    if (params.email && params.password) {
                    } else if (params.google_token) {
                        if (window.utag) {
                            setTimeout(() => {
                                let user = getState().user;

                                let tealium_dict = {
                                    page_name: "Grow Smart Live",
                                    customer_email: user.email,
                                    customer_status: "logged in",
                                    customer_state: user.state,
                                    customer_id: user.uid,
                                    customer_type: user.groupName,
                                    view_name: "login",
                                    page_category: "Login",
                                    event_type: "click",
                                    event_name: `login_with_google`,
                                    tealium_event: `login_with_google`,
                                };
                                window.utag.link(tealium_dict);
                            }, 1000);
                        }
                    } else if (params.facebook_data) {
                        setTimeout(() => {
                            setTimeout(() => {
                                let user = getState().user;

                                let tealium_dict = {
                                    page_name: "Grow Smart Live",
                                    customer_email: user.email,
                                    customer_status: "logged in",
                                    customer_state: user.state,
                                    customer_id: user.uid,
                                    customer_type: user.groupName,
                                    view_name: "login",
                                    page_category: "Login",
                                    event_type: "click",
                                    event_name: `login_with_facebook`,
                                    tealium_event: `login_with_facebook`,
                                };
                                window.utag.link(tealium_dict);
                            }, 1000);
                        }, 100);
                    } else {
                    }

                    callback(true, x.data);
                    dispatch(setLogin(x.data));
                }
            }
        });
    };
}

export function setLogin(info) {
    return {
        type: types.SET_LOGIN,
        info,
    };
}

/*
  Control logout
*/
export function logout() {
    return (dispatch, getState) => {
        // bugsnagClient.user = {};

        const params = "is_web=True";

        executeBasicNetworkCall(
            "logout",
            params,
            "application/x-www-form-urlencoded",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);

                    if (x.error) {
                        console.log(x.message);
                    } else {
                        // console.log(x.message);
                    }
                }
            },
            (error) => {
                console.log(error);
            },
        );

        //TODO clear all redux states here!!!
        dispatch(executeLogout());
        dispatch(clearNewsContent());
        dispatch(clearObservationsContent());
        dispatch(clearVideosContent());
        dispatch(clearLRF());
        dispatch(clearGSAT());
    };
}

function executeLogout() {
    return {
        type: types.LOGOUT,
    };
}

export function verifyUserInfo() {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "verify_user_info",
            null,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        // console.log(x.message);
                        if (x.code === "logout") {
                            dispatch(executeLogout());
                        } else if (x.code === "error") {
                            // open data input modal
                        }
                    } else {
                        // console.log(x.message);
                        dispatch(setLogin(x.data));
                    }
                }
            },
            (error) => {
                console.log(error);
            },
        );
    };
}
