import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import { connect } from "react-redux";
import { ActionCreators } from "redux/actions";
import { bindActionCreators } from "redux";

import Column from "components/Flexbox/Column";
import Row from "components/Flexbox/Row";
import LiteToFullModal from "components/LiteToFullModal";
import NavigationBar from "ContentArea/internal/NavigationBar/NavigationBar";
import { renderCustomRoute } from "ContentArea/internal/CustomRender";

import { AuthGroup, HOST, LiteLockedFeatures } from "Constants";

import "./ContentAreaScreen.css";

//Content
const HomeScreen = React.lazy(() => import("Home/screens/HomeScreen"));
const NewsScreen = React.lazy(() => import("News/screens/NewsScreen"));
const NewsDetailScreen = React.lazy(() => import("News/screens/NewsDetailScreen"));
const EditorVideoScreen = React.lazy(() => import("Videos/screens/EditorVideoScreen"));
const VideosScreen = React.lazy(() => import("Videos/screens/VideosScreen"));
const VideosDetailScreen = React.lazy(() => import("Videos/screens/VideosDetailScreen"));
const ObservationsScreen = React.lazy(() => import("Observations/screens/ObservationsScreen"));
const ObservationsDetailScreen = React.lazy(() => import("Observations/screens/ObservationsDetailScreen"));
const LabelsScreen = React.lazy(() => import("Labels/screens/LabelsScreen"));
// const ForumsScreen = React.lazy(() => import("Forums/screens/ForumsScreen"));
// const WeatherScreen = React.lazy(() => import("Weather/screens/WeatherScreen"));

//Tools
const IntroducingGSATScreen = React.lazy(() => import("GSAT/screens/IntroducingGSATScreen"));
const GSATScreen = React.lazy(() => import("GSAT/screens/GSATScreen"));
// const SmartRebatesScreen = React.lazy(() => import("SmartRebates/screens/SmartRebatesScreen"));
const PlantHealthScreen = React.lazy(() => import("PlantHealth/screens/PlantHealthScreen"));
const OnFarmScreen = React.lazy(() => import("OnFarm/screens/OnFarmScreen"));
const LRFScreen = React.lazy(() => import("LRF/screens/LRFScreen"));

//Personal
// const MyAccountScreen = React.lazy(() => import("MyAccount/screens/MyAccountScreen"));
const AboutAppScreen = React.lazy(() => import("AboutApp/screens/AboutAppScreen"));
const ZipCodeModal = React.lazy(() => import("components/ZipCodeModal"));

//Search
const SearchResultsScreen = React.lazy(() => import("Search/screens/SearchResultsScreen"));

//Legal
const TermsAndConditions = React.lazy(() => import("containers/TermsAndConditionsScreen"));
const DataPrivacyScreen = React.lazy(() => import("Search/screens/SearchResultsScreen"));

function ContentAreaScreen(props) {

    useEffect(
        () => {
            props.verifyUserInfo();
        },
        // eslint-disable-next-line
        [],
    );

    const renderRoute = ({ Component, routeProps = {}, isWebview = false, divParent = false, customProps = {}, }) => {
        return renderCustomRoute({ Component, routeProps, isWebview, divParent, customProps, props });
    };

    //Content
    const renderHome = (routeProps) => renderRoute({ Component: HomeScreen, routeProps });
    const renderNews = (routeProps) => renderRoute({ Component: NewsScreen, routeProps });
    const renderNewsDetail = (routeProps) => renderRoute({ Component: NewsDetailScreen, routeProps });
    const renderEditorVideoScreen = (routeProps) => renderRoute({ Component: EditorVideoScreen, routeProps, divParent: true });
    const renderVideos = (routeProps) => renderRoute({ Component: VideosScreen, routeProps });
    const renderVideosDetail = (routeProps) => renderRoute({ Component: VideosDetailScreen, routeProps });
    const renderObservations = (routeProps) => renderRoute({ Component: ObservationsScreen, routeProps });
    const renderObservationsDetail = (routeProps) => renderRoute({ Component: ObservationsDetailScreen, routeProps });
    const renderLabels = (routeProps, customProps) => renderRoute({ Component: LabelsScreen, routeProps, customProps });
    // const renderForums = (routeProps) => renderRoute({ Component: ForumsScreen, routeProps });
    // const renderWeather = (routeProps) => renderRoute({ Component: WeatherScreen, routeProps });

    //Tools
    const renderIntroducingGSAT = (routeProps, customProps) => renderRoute({ Component: IntroducingGSATScreen, routeProps, customProps });
    const renderGSAT = (routeProps, customProps) => renderRoute({ Component: GSATScreen, routeProps, customProps });
    // const renderSmartRebates = (routeProps) => renderRoute({ Component: SmartRebatesScreen, routeProps });
    const renderPlantHealth = (routeProps, customProps) => renderRoute({ Component: PlantHealthScreen, routeProps, customProps });
    const renderOnFarm = (routeProps, customProps) => renderRoute({ Component: OnFarmScreen, routeProps, customProps });
    const renderLRF = (routeProps, customProps) => renderRoute({ Component: LRFScreen, routeProps, customProps });

    //Personal
    // const renderMyAccount = (routeProps) => renderRoute({ Component: MyAccountScreen, routeProps });
    const renderAboutApp = (routeProps) => renderRoute({ Component: AboutAppScreen, routeProps });
    const renderZipCodeModal = (routeProps) => renderRoute({ Component: ZipCodeModal, routeProps });

    //Search
    const renderSearchResults = (routeProps) => renderRoute({ Component: SearchResultsScreen, routeProps });

    //Legal
    const renderTermsAndConditions = (routeProps) => renderRoute({ Component: TermsAndConditions, routeProps });
    const renderDataPrivacy = (routeProps) => renderRoute({ Component: DataPrivacyScreen, routeProps });

    const renderRoutesForAccountType = () => {
        const accountType = props.user.groupID;

        if (accountType === AuthGroup.AccountManager) {
            return (
                <Switch>
                    {/* Content */}
                    <Route exact path="/" render={renderHome} />
                    <Route exact path="/news" render={renderNews} />
                    <Route exact path="/news/:article_id" render={renderNewsDetail} />
                    <Route exact path="/videos" render={renderVideos} />
                    <Route exact path="/videos/:video_id" render={renderVideosDetail} />
                    <Route exact path="/BASF/videos/:locator_id/:video_id" render={renderEditorVideoScreen} />
                    <Route exact path="/observations" render={renderObservations} />
                    <Route exact path="/observations/:observation_id" render={renderObservationsDetail} />
                    <Route exact path="/labels" render={renderLabels} />
                    {/*<Route exact path="/forums" render={renderForums} />*/}
                    {/* <Route exact path="/weather" render={renderWeather} /> */}
    
                    {/* Tools */}
                    <Route exact path="/gsat" render={renderIntroducingGSAT} />
                    <Route exact path="/gsat/tool" render={renderGSAT} />
                    {/*<Route exact path="/smart_rebates" render={renderSmartRebates} />*/}
                    <Route exact path="/plant_health" render={renderPlantHealth} />
                    <Route exact path="/farm_trials" render={renderOnFarm} />
                    <Route exact path="/lrf" render={renderLRF} />
    
                    {/* Personal */}
                    {/*<Route exact path="/my_account" render={renderMyAccount} />*/}
                    <Route exact path="/about_app" render={renderAboutApp} />
    
                    {/*Search*/}
                    <Route exact path="/search/:search_term" render={renderSearchResults} />
    
                    {/*Legal*/}
                    <Route exact path="/terms_and_conditions" render={renderTermsAndConditions} />
                    <Route exact path="/data_privacy" render={renderDataPrivacy} />
    
                    <Route render={() => <Redirect to={"/"} />} />
                </Switch>
            );
        } else if (accountType === AuthGroup.ProjectManager) {
            return (
                <Switch>
                    {/* Content */}
                    <Route exact path="/" render={renderHome} />
                    <Route exact path="/news" render={renderNews} />
                    <Route exact path="/news/:article_id" render={renderNewsDetail} />
                    <Route exact path="/videos" render={renderVideos} />
                    <Route exact path="/videos/:video_id" render={renderVideosDetail} />
                    <Route exact path="/BASF/videos/:locator_id/:video_id" render={renderEditorVideoScreen} />
                    <Route exact path="/observations" render={renderObservations} />
                    <Route exact path="/observations/:observation_id" render={renderObservationsDetail} />
                    <Route exact path="/labels" render={renderLabels} />
                    {/*<Route exact path="/forums" render={renderForums} />*/}
                    {/* <Route exact path="/weather" render={renderWeather} /> */}
    
                    {/* Tools */}
                    <Route exact path="/gsat" render={renderIntroducingGSAT} />
                    <Route exact path="/gsat/tool" render={renderGSAT} />
                    {/*<Route exact path="/smart_rebates" render={renderSmartRebates} />*/}
                    <Route exact path="/plant_health" render={renderPlantHealth} />
                    <Route exact path="/farm_trials" render={renderOnFarm} />
                    <Route exact path="/lrf" render={renderLRF} />
    
                    {/* Personal */}
                    {/*<Route exact path="/my_account" render={renderMyAccount} />*/}
                    <Route exact path="/about_app" render={renderAboutApp} />
    
                    {/*Search*/}
                    <Route exact path="/search/:search_term" render={renderSearchResults} />
    
                    {/*Legal*/}
                    <Route exact path="/terms_and_conditions" render={renderTermsAndConditions} />
                    <Route exact path="/data_privacy" render={renderDataPrivacy} />
    
                    <Route render={() => <Redirect to={"/"} />} />
                </Switch>
            );
        } else if (accountType === AuthGroup.Author) {
            return (
                <Switch>
                    {/* Content */}
                    <Route exact path="/" render={renderHome} />
                    <Route exact path="/news" render={renderNews} />
                    <Route exact path="/news/:article_id" render={renderNewsDetail} />
                    <Route exact path="/videos" render={renderVideos} />
                    <Route exact path="/videos/:video_id" render={renderVideosDetail} />
                    <Route exact path="/BASF/videos/:locator_id/:video_id" render={renderEditorVideoScreen} />
                    <Route exact path="/observations" render={renderObservations} />
                    <Route exact path="/observations/:observation_id" render={renderObservationsDetail} />
                    <Route exact path="/labels" render={renderLabels} />
                    {/*<Route exact path="/forums" render={renderForums} />*/}
                    {/* <Route exact path="/weather" render={renderWeather} /> */}
    
                    {/* Tools */}
                    <Route exact path="/gsat" render={renderIntroducingGSAT} />
                    <Route exact path="/gsat/tool" render={renderGSAT} />
                    {/*<Route exact path="/smart_rebates" render={renderSmartRebates} />*/}
                    <Route exact path="/plant_health" render={renderPlantHealth} />
                    <Route exact path="/farm_trials" render={renderOnFarm} />
                    <Route exact path="/lrf" render={renderLRF} />
    
                    {/* Personal */}
                    {/*<Route exact path="/my_account" render={renderMyAccount} />*/}
                    <Route exact path="/about_app" render={renderAboutApp} />
    
                    {/*Search*/}
                    <Route exact path="/search/:search_term" render={renderSearchResults} />
    
                    {/*Legal*/}
                    <Route exact path="/terms_and_conditions" render={renderTermsAndConditions} />
                    <Route exact path="/data_privacy" render={renderDataPrivacy} />
    
                    <Route render={() => <Redirect to={"/"} />} />
                </Switch>
            );
        } else if (accountType === AuthGroup.Advocate) {
            return (
                <Switch>
                    {/* Content */}
                    <Route exact path="/" render={renderHome} />
                    <Route exact path="/news" render={renderNews} />
                    <Route exact path="/news/:article_id" render={renderNewsDetail} />
                    <Route exact path="/videos" render={renderVideos} />
                    <Route exact path="/videos/:video_id" render={renderVideosDetail} />
                    <Route exact path="/BASF/videos/:locator_id/:video_id" render={renderEditorVideoScreen} />
                    <Route exact path="/observations" render={renderObservations} />
                    <Route exact path="/observations/:observation_id" render={renderObservationsDetail} />
                    <Route exact path="/labels" render={renderLabels} />
                    {/*<Route exact path="/forums" render={renderForums} />*/}
                    {/* <Route exact path="/weather" render={renderWeather} /> */}
    
                    {/* Tools */}
                    <Route exact path="/gsat" render={renderIntroducingGSAT} />
                    <Route exact path="/gsat/tool" render={renderGSAT} />
                    {/*<Route exact path="/smart_rebates" render={renderSmartRebates} />*/}
                    <Route exact path="/plant_health" render={renderPlantHealth} />
                    <Route exact path="/farm_trials" render={renderOnFarm} />
                    <Route exact path="/lrf" render={renderLRF} />
    
                    {/* Personal */}
                    {/*<Route exact path="/my_account" render={renderMyAccount} />*/}
                    <Route exact path="/about_app" render={renderAboutApp} />
    
                    {/*Search*/}
                    <Route exact path="/search/:search_term" render={renderSearchResults} />
    
                    {/*Legal*/}
                    <Route exact path="/terms_and_conditions" render={renderTermsAndConditions} />
                    <Route exact path="/data_privacy" render={renderDataPrivacy} />
    
                    <Route render={() => <Redirect to={"/"} />} />
                </Switch>
            );
        } else if (accountType === AuthGroup.PracticeAuthor) {
            return (
                <Switch>
                    {/* Content */}
                    <Route exact path="/" render={renderHome} />
                    <Route exact path="/news" render={renderNews} />
                    <Route exact path="/news/:article_id" render={renderNewsDetail} />
                    <Route exact path="/videos" render={renderVideos} />
                    <Route exact path="/videos/:video_id" render={renderVideosDetail} />
                    <Route exact path="/BASF/videos/:locator_id/:video_id" render={renderEditorVideoScreen} />
                    <Route exact path="/observations" render={renderObservations} />
                    <Route exact path="/observations/:observation_id" render={renderObservationsDetail} />
                    <Route exact path="/labels" render={renderLabels} />
                    {/*<Route exact path="/forums" render={renderForums} />*/}
                    {/* <Route exact path="/weather" render={renderWeather} /> */}
    
                    {/* Tools */}
                    <Route exact path="/gsat" render={renderIntroducingGSAT} />
                    <Route exact path="/gsat/tool" render={renderGSAT} />
                    {/*<Route exact path="/smart_rebates" render={renderSmartRebates} />*/}
                    <Route exact path="/plant_health" render={renderPlantHealth} />
                    <Route exact path="/farm_trials" render={renderOnFarm} />
                    <Route exact path="/lrf" render={renderLRF} />
    
                    {/* Personal */}
                    {/*<Route exact path="/my_account" render={renderMyAccount} />*/}
                    <Route exact path="/about_app" render={renderAboutApp} />
    
                    {/*Search*/}
                    <Route exact path="/search/:search_term" render={renderSearchResults} />
    
                    {/*Legal*/}
                    <Route exact path="/terms_and_conditions" render={renderTermsAndConditions} />
                    <Route exact path="/data_privacy" render={renderDataPrivacy} />
    
                    <Route render={() => <Redirect to={"/"} />} />
                </Switch>
            );
        } else if (accountType === AuthGroup.InternalViewer) {
            return (
                <Switch>
                    {/* Content */}
                    <Route exact path="/" render={renderHome} />
                    <Route exact path="/news" render={renderNews} />
                    <Route exact path="/news/:article_id" render={renderNewsDetail} />
                    <Route exact path="/videos" render={renderVideos} />
                    <Route exact path="/videos/:video_id" render={renderVideosDetail} />
                    <Route exact path="/BASF/videos/:locator_id/:video_id" render={renderEditorVideoScreen} />
                    <Route exact path="/observations" render={renderObservations} />
                    <Route exact path="/observations/:observation_id" render={renderObservationsDetail} />
                    <Route exact path="/labels" render={renderLabels} />
                    {/*<Route exact path="/forums" render={renderForums} />*/}
                    {/* <Route exact path="/weather" render={renderWeather} /> */}
    
                    {/* Tools */}
                    <Route exact path="/gsat" render={renderIntroducingGSAT} />
                    <Route exact path="/gsat/tool" render={renderGSAT} />
                    {/*<Route exact path="/smart_rebates" render={renderSmartRebates} />*/}
                    <Route exact path="/plant_health" render={renderPlantHealth} />
                    <Route exact path="/farm_trials" render={renderOnFarm} />
                    <Route exact path="/lrf" render={renderLRF} />
    
                    {/* Personal */}
                    {/*<Route exact path="/my_account" render={renderMyAccount} />*/}
                    <Route exact path="/about_app" render={renderAboutApp} />
    
                    {/*Search*/}
                    <Route exact path="/search/:search_term" render={renderSearchResults} />
    
                    {/*Legal*/}
                    <Route exact path="/terms_and_conditions" render={renderTermsAndConditions} />
                    <Route exact path="/data_privacy" render={renderDataPrivacy} />
    
                    <Route render={() => <Redirect to={"/"} />} />
                </Switch>
            );
        } else if (accountType === AuthGroup.Viewer) {
            return (
                <Switch>
                    {/* Content */}
                    <Route exact path="/" render={renderHome} />
                    <Route exact path="/news" render={renderNews} />
                    <Route exact path="/news/:article_id" render={renderNewsDetail} />
                    <Route exact path="/videos" render={renderVideos} />
                    <Route exact path="/videos/:video_id" render={renderVideosDetail} />
                    <Route exact path="/BASF/videos/:locator_id/:video_id" render={renderEditorVideoScreen} />
                    <Route exact path="/observations" render={renderObservations} />
                    <Route exact path="/observations/:observation_id" render={renderObservationsDetail} />
                    <Route exact path="/labels" render={renderLabels} />
                    {/*<Route exact path="/forums" render={renderForums} />*/}
                    {/* <Route exact path="/weather" render={renderWeather} /> */}
    
                    {/* Tools */}
                    <Route exact path="/gsat" render={renderIntroducingGSAT} />
                    <Route exact path="/gsat/tool" render={renderGSAT} />
                    {/*<Route exact path="/smart_rebates" render={renderSmartRebates} />*/}
                    <Route exact path="/plant_health" render={renderPlantHealth} />
                    <Route exact path="/farm_trials" render={renderOnFarm} />
                    <Route exact path="/lrf" render={renderLRF} />
    
                    {/* Personal */}
                    {/*<Route exact path="/my_account" render={renderMyAccount} />*/}
                    <Route exact path="/about_app" render={renderAboutApp} />
    
                    {/*Search*/}
                    <Route exact path="/search/:search_term" render={renderSearchResults} />
    
                    {/*Legal*/}
                    <Route exact path="/terms_and_conditions" render={renderTermsAndConditions} />
                    <Route exact path="/data_privacy" render={renderDataPrivacy} />
    
                    <Route render={() => <Redirect to={"/"} />} />
                </Switch>
            );
        } else if (accountType === AuthGroup.Lite) {
            return (
                <Switch>
                    {/* Content */}
                    <Route exact path="/" render={renderHome} />
                    <Route exact path="/news" render={renderNews} />
                    <Route exact path="/news/:article_id" render={renderNewsDetail} />
                    <Route exact path="/videos" render={renderVideos} />
                    <Route exact path="/videos/:video_id" render={renderVideosDetail} />
                    <Route exact path="/BASF/videos/:locator_id/:video_id" render={renderEditorVideoScreen} />
                    <Route exact path="/observations" render={renderObservations} />
                    <Route exact path="/observations/:observation_id" render={renderObservationsDetail} />
                    <Route exact path="/labels" render={(routeProps) => renderLabels(routeProps, {isLocked: LiteLockedFeatures.Labels})} />
                    {/*<Route exact path="/forums" render={renderForums} />*/}
                    {/* <Route exact path="/weather" render={renderWeather} /> */}
    
                    {/* Tools */}
                    <Route exact path="/gsat" render={(routeProps) => renderIntroducingGSAT(routeProps, {isLocked: LiteLockedFeatures.GSAT})} />
                    <Route exact path="/gsat/tool" render={(routeProps) => renderGSAT(routeProps, {isLocked: LiteLockedFeatures.GSAT})} />
                    {/*<Route exact path="/smart_rebates" render={renderSmartRebates} />*/}
                    <Route exact path="/plant_health" render={(routeProps) => renderPlantHealth(routeProps, {isLocked: LiteLockedFeatures.PlantHealth})} />
                    <Route exact path="/farm_trials" render={(routeProps) => renderOnFarm(routeProps, {isLocked: LiteLockedFeatures.FarmTrials})} />
                    <Route exact path="/lrf" render={(routeProps) => renderLRF(routeProps, {isLocked: LiteLockedFeatures.LRF})} />
    
                    {/* Personal */}
                    {/*<Route exact path="/my_account" render={renderMyAccount} />*/}
                    <Route exact path="/about_app" render={renderAboutApp} />
    
                    {/*Search*/}
                    <Route exact path="/search/:search_term" render={renderSearchResults} />
    
                    {/*Legal*/}
                    <Route exact path="/terms_and_conditions" render={renderTermsAndConditions} />
                    <Route exact path="/data_privacy" render={renderDataPrivacy} />
    
                    <Route render={() => <Redirect to={"/"} />} />
                </Switch>
            );
        } else if (accountType === AuthGroup.Developer) {
            return (
                <div>Go to <a href={HOST}>{HOST}</a> for developer access</div>
            );
        }

        return (
            <div></div>
        );
    };

    const shouldRenderLocationModal =
        props.user.zip_code === null
        || props.user.zip_code === undefined
        || props.user.zip_code === ""
        || props.user.county_id === null
        || props.user.county_id === undefined
        || props.user.county_id === "";

    return (
        <Column className="content-area-screen__main-container">
            <Row className="content-area" justifyContent="center" alignItems="flex-start">
                <Column className="content-area-container">
                    {shouldRenderLocationModal ? renderZipCodeModal() : null}
                    { props.user.openUpgradeModal && <LiteToFullModal /> }
                    <NavigationBar />
                    <Row className="content-area-routes-row">{renderRoutesForAccountType()}</Row>
                </Column>
            </Row>
        </Column>
    );
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps, mapDispatchToProps, null)(ContentAreaScreen);
