import './Radio.scss';

export default function Radio({
    name = "",
    label = "",
    checked = false,
    onChange = () => {},
    style = {}
}) {
    return (
        <label className="semantic-radio" onChange={onChange} style={style}>
            <input type="radio" className="semantic-radio__input" name={name} />
            <span className={`semantic-radio__span ${checked ? "semantic-radio__span--selected" : ""}`}></span>
            <span className="semantic-radio__label">{label}</span>
        </label>
    );
}
