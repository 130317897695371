import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web and AsyncStorage for react-native

import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";

import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

import rootReducer from "redux/reducers";

const persistConfig = {
    key: "gsl",
    storage,
    stateReconciler: autoMergeLevel2,
    whitelist: [ "endpoints", "user" ],
    //blacklist: ['navigation']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// middleware that logs actions
//const loggerMiddleware = createLogger({ predicate: (getState, action) => __DEV__  });

function configureStore(persistedReducer) {
    const DEV = process.env.NODE_ENV !== "production";

    const logger = createLogger({
        collapsed: () => true,
    });

    const middlewares = [thunkMiddleware, DEV && logger].filter(Boolean);

    const enhancer = compose(
        applyMiddleware(...middlewares),
        // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    );
    return createStore(persistedReducer, undefined, enhancer);
}

export const store = configureStore(persistedReducer);
export const persistor = persistStore(store);
