import { executeBasicNetworkCall } from "lib/Utils";
import { store } from "redux/store";
import * as types from "LRF/actions/types";

export function clearLRF() {
    return (dispatch) => {
        dispatch(clearSpatialLRFMaps());
        dispatch(clearLocationSpecificLRFMaps());
    };
}

export function getSpatialLRFMaps(callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall("get_spatial_lrf_maps", undefined, "application/json", "POST", store.getState, (reqq) => {
            if (reqq.responseText != null) {
                var x = JSON.parse(reqq.responseText);
                if (x.error) {
                    callback(false);
                } else {
                    callback(true, x.data);
                    dispatch(setSpatialLRFMaps(x.data));
                }
            }
        });
    };
}

export function clearSpatialLRFMaps() {
    return (dispatch) => {
        dispatch(setSpatialLRFMaps());
    };
}

function setSpatialLRFMaps(maps = {}) {
    return {
        type: types.SET_SPATIAL_LRF_MAPS,
        maps,
    };
}

export function getLocationSpecificLRF(callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall("get_location_specific_lrf", undefined, "application/json", "POST", store.getState, (reqq) => {
            if (reqq.responseText != null) {
                var x = JSON.parse(reqq.responseText);
                if (x.error) {
                    callback(false);
                } else {
                    callback(true, x.data);
                    dispatch(setLocationSpecificLRF(x.data));
                }
            }
        });
    };
}

export function clearLocationSpecificLRFMaps() {
    return (dispatch) => {
        dispatch(setLocationSpecificLRF());
    };
}

function setLocationSpecificLRF(data = {}) {
    return {
        type: types.SET_LOCATION_SPECIFIC_LRF,
        data,
    };
}
