import { useState, useEffect, useMemo } from "react";
import Column from "components/Flexbox/Column";
import Row from "components/Flexbox/Row";
import NavigationBarItem from "./NavigationBarItem";

import { ReactComponent as Home } from "./assets/Home.svg";
import { ReactComponent as OnFarm } from "./assets/OnFarm.svg";
import { ReactComponent as GSAT } from "./assets/GSAT.svg";
import { ReactComponent as Labels } from "./assets/Labels.svg";

import { ReactComponent as News } from "./assets/News.svg";
import { ReactComponent as Videos } from "./assets/Videos.svg";
import { ReactComponent as Observations } from "./assets/Observations.svg";
// import { ReactComponent as Forums } from "./assets/Forums.svg";
// import { ReactComponent as Weather } from "./assets/Weather.svg";

import { ReactComponent as PlantHealth } from "./assets/PlantHealth.svg";
import { ReactComponent as LRF } from "./assets/LRF.svg";

import { ReactComponent as MyAccount } from "./assets/MyAccount.svg";
import { ReactComponent as AboutApp } from "./assets/AboutApp.svg";

import { ReactComponent as Search } from "./assets/Search.svg";

import exclamationIcon from "./assets/exclamation.svg";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { AuthActionCreators } from "Authentication/actions";

import "./NavigationBar.css";
import { useLocation } from "react-router-dom";
import { AuthGroup, LiteLockedFeatures } from "Constants";
import FullScreenSearch from "Search/FullScreenSearch";

function NavigationBar({ isResponsive, userGroupID, ...props }) {
    const [extraTab, setExtraTab] = useState(null);
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    let isLite = false;
    if (userGroupID === AuthGroup.Lite) {
        isLite = true;
    }

    const FixedTabItems = useMemo(
        () => [
            { name: "Home", image: (props) => <Home {...props} />, locked: isLite ? LiteLockedFeatures.Home : false, link: "/", exact: true },
            { name: "RevX Fields", image: (props) => <OnFarm {...props} />, locked: isLite ? LiteLockedFeatures.FarmTrials : false, link: "/farm_trials" },
            { name: "Advantage", image: (props) => <GSAT {...props} />, locked: isLite ? LiteLockedFeatures.GSAT : false, link: "/gsat" },
            { name: "Labels", image: (props) => <Labels {...props} />, locked: isLite ? LiteLockedFeatures.Labels : false, link: "/labels" },
            { name: "Forecast", image: (props) => <LRF {...props} />, locked: isLite ? LiteLockedFeatures.LRF : false, link: "/lrf" },
        ],
        [isLite],
    );

    const ExtraTabItems = useMemo(
        () => [
            { name: "Articles", image: (props) => <News {...props} />, locked: false, link: "/news" },
            { name: "Videos", image: (props) => <Videos {...props} />, locked: false, link: "/videos" },
            { name: "Observations", image: (props) => <Observations {...props} />, locked: false, link: "/observations" },
            // { name: "Forums", image: (props) => <Forums {...props} />, locked: false, link: "/forums" },
            // { name: "Weather", image: (props) => <Weather {...props} />, locked: false, link: "/weather" },
            { name: "Plant Health", image: (props) => <PlantHealth {...props} />, locked: isLite ? LiteLockedFeatures.PlantHealth : false, link: "/plant_health" },
            { name: "My Account", image: (props) => <MyAccount {...props} />, locked: false, link: "/my_account" },
            { name: "About App", image: (props) => <AboutApp {...props} />, locked: false, link: "/about_app" },
            { name: "Search", image: (props) => <Search {...props} />, locked: false, link: "/search" },
            { name: "Terms & Conditions", image: (props) => <PlantHealth {...props} />, locked: false, link: "/terms_and_conditions" },
        ],
        [isLite],
    );

    let location = useLocation();
    useEffect(() => {
        let isOnFixedTab = false;
        for (let i = 0; i < FixedTabItems.length; i++) {
            if (
                FixedTabItems[i].link !== "" &&
                FixedTabItems[i].link !== "/" &&
                location.pathname.startsWith(FixedTabItems[i].link)
            ) {
                isOnFixedTab = true;
            } else if (location.pathname === "" || location.pathname === "/") {
                isOnFixedTab = true;
            }
        }

        if (isOnFixedTab) {
            setExtraTab(null);
        } else {
            for (let j = 0; j < ExtraTabItems.length; j++) {
                if (location.pathname.startsWith(ExtraTabItems[j].link)) {
                    setExtraTab(ExtraTabItems[j]);
                }
            }
        }
    }, [location.pathname, ExtraTabItems, FixedTabItems]);

    return (
        <Column>
            <Row className="nav-bar__outer-container" justifyContent="center" alignItems="flex-end">
                <Row className="nav-bar__inner-container" justifyContent="space-around" alignItems="flex-end">
                    {FixedTabItems.map((item, index) => {
                        return (
                            <NavigationBarItem
                                key={index}
                                exact={item.exact}
                                name={item.name}
                                image={item.image}
                                locked={item.locked}
                                link={item.link}
                                isResponsive={isResponsive}
                            />
                        );
                    })}
                    {extraTab && <div className="nav-bar__vertical-divider"></div>}
                    {extraTab && (
                        <NavigationBarItem
                            name={extraTab.name}
                            exact={extraTab.exact}
                            image={extraTab.image}
                            locked={extraTab.locked}
                            link={extraTab.link}
                            setIsSearchOpen={setIsSearchOpen}
                        />
                    )}
                </Row>
                {isSearchOpen && <FullScreenSearch closeSearch={() => setIsSearchOpen(false)} />}
            </Row>
            {isLite &&
                <Row className="nav-bar__banner">
                    <img src={exclamationIcon} alt="Exclamation Icon" className="nav-bar__banner-icon" />
                    <Row alignItems="center">
                        You are currently viewing this site as a LITE account user.
                        To upgrade and unlock all features, please provide us with a little more information.
                        <div className="nav-bar__banner-clickable" onClick={() => props.setOpenUpgradeModal(true)}>
                            Upgrade Now
                        </div>
                    </Row>
                </Row>
            }
        </Column>
    );
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(AuthActionCreators, dispatch);
}

function mapStateToProps(state) {
    return {
        isResponsive: state.responsive.isResponsive,
        userGroupID: state.user.groupID,
    };
}

export default connect(mapStateToProps, mapDispatchToProps, null)(NavigationBar);
