import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Column from "components/Flexbox/Column";
import CloseIcon from "assets/CloseIcon";
import "./FullScreenSearch.scss";

function FullScreenSearch({ closeSearch = () => {} }) {
    const [searchTerm, setSearchTerm] = useState("");
    const searchInputRef = useRef(null);
    const history = useHistory();

    const onKeyPress = (e) => {
        if (e.key === "Enter") {
            setSearchTerm("");
            history.push("/search/" + searchTerm);
            closeSearch();
        }
    };

    return (
        <div className="full-screen-search">
            <Column className="full-screen-search__search-column">
                <CloseIcon className="full-screen-search__close-search" onClick={closeSearch} />
                <p
                    className="full-screen-search__prompt"
                    onClick={() => (searchInputRef.current ? searchInputRef.current.focus() : {})}
                >
                    Type your keyword and hit Enter...
                </p>
                <input
                    ref={searchInputRef}
                    className="full-screen-search__search-input"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyPress={onKeyPress}
                />
            </Column>
        </div>
    );
}

export default FullScreenSearch;
