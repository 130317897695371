import React from "react";
import Column from "components/Flexbox/Column";
import Row from "components/Flexbox/Row";
import "./AccountPopup.scss";

import myProfileIcon from "components/TopBar/internal/assets/my-profile.svg";
import termsIcon from "components/TopBar/internal/assets/terms.svg";
import privacyIcon from "components/TopBar/internal/assets/privacy.svg";
// import settingsIcon from "components/TopBar/internal/assets/settings.svg";
import logoutIcon from "components/TopBar/internal/assets/logout.svg";

import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { ActionCreators } from "redux/actions";
import { bindActionCreators } from "redux";

function AccountPopup(props) {
    const Divider = () => <div className="my-account-popup__divider" />;

    const renderRow = (
        name = "",
        icon = "",
        link = "",
        href = "",
    ) => {
        if (link !== "" && link) {
            return (
                <Link to={link} className="my-account-popup__row" onClick={props.closePopup}>
                    <Row alignItems="center">
                        {icon && <img src={icon} alt={name} className="my-account-popup__icon-container" />}
                        <p className="my-account-popup__row-text">{name}</p>
                    </Row>
                </Link>
            )
        }
        return (
            <a href={href} className="my-account-popup__row" onClick={props.closePopup} target="_blank" rel="noopener noreferrer">
                <Row alignItems="center">
                    {icon && <img src={icon} alt={name} className="my-account-popup__icon-container" />}
                    <p className="my-account-popup__row-text">{name}</p>
                </Row>
            </a>
        )
    }

    return (
        <Column className="my-account-popup">
            <h1 className="my-account-popup__header">My Account</h1>
            <Row className="my-account-popup__profile-row" alignItems="center">
                <img
                    className="my-account-popup__image"
                    src={props.user.profile_pic ? props.user.profile_pic : myProfileIcon}
                    alt={props.user.fullName}
                />
                <Column justifyContent="center">
                    <div className="my-account-popup__profile-row-name">{props.user.fullName}</div>
                    <div>{props.user.groupName}</div>
                </Column>
            </Row>
            {renderRow("Terms & Conditions", termsIcon, "/terms_and_conditions" )}
            {<Divider />}
            {renderRow("Data Privacy", privacyIcon, "", "https://www.basf.com/us/en/legal/data-protection.html")}
            {<Divider />}
            <Row alignItems="center" onClick={() => props.logout()} className="my-account-popup__row">
                <img src={logoutIcon} alt='logout' className="my-account-popup__icon-container" />
                <p className="my-account-popup__row-text">Logout</p>
            </Row>
        </Column>
    );
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps, mapDispatchToProps, null)(AccountPopup);
