import Row from "components/Flexbox/Row";
import "./CarouselArrows.scss";

export const ArrowStyle = {
    SQUARE: "square",
    CIRCLE: "circle",
};

export default function Arrows({
    type = ArrowStyle.SQUARE,
    rightDisabled = false,
    onRightClick = () => console.log("clicked right"),
    leftDisabled = false,
    onLeftClick = () => console.log("clicked left"),
    color = "#018F5C",
    size = 35,
}) {
    const svgStyle = { backgroundColor: color };
    if (type === ArrowStyle.CIRCLE) {
        svgStyle.borderRadius = size;
    }

    return (
        <Row>
            <svg
                width={size}
                height={size}
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={`carousel-arrows__left ${leftDisabled ? "carousel-arrows--disabled" : ""}`}
                onClick={onLeftClick}
                style={{ opacity: leftDisabled ? 0.5 : 1, ...svgStyle }}
            >
                <rect width="40" height="40" rx="6" fill={color} opacity={leftDisabled ? 0.5 : 1} />
                <path d="M23.512 10 14 19.512l9.512 9.511 2.356-2.356-7.155-7.155 7.155-7.155L23.512 10z" fill="#fff" />
            </svg>
            <svg
                width={size}
                height={size}
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={`carousel-arrows ${rightDisabled ? "carousel-arrows--disabled" : ""}`}
                onClick={onRightClick}
                style={{ opacity: rightDisabled ? 0.5 : 1, ...svgStyle }}
            >
                <rect width="40" height="40" rx="6" fill={color} opacity={rightDisabled ? 0.5 : 1} />
                <path d="M17.845 29.512 27.357 20l-9.512-9.512-2.357 2.357L22.643 20l-7.155 7.155 2.357 2.357z" fill="#fff" />
            </svg>
        </Row>
    );
}
