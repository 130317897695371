import {
    CONTENT_TYPES,
    getContentDetails,
    getPlaylistStream,
    getPlaylistCategories,
    getStateStream,
    getRelatedContent,
    setUserViewPost,
    setUserLikePost,
    setUserUnlikePost,
} from "ContentStream";
import * as types from "Videos/actions/types";

export function clearVideosContent() {
    return (dispatch) => {
        dispatch(clearVideoDetails());
        dispatch(clearVideosPlaylists());
        dispatch(clearVideosPlaylistCategories());
        dispatch(clearRelatedVideos());
        dispatch(clearVideosStateStream());
    };
}

export function getVideosStateStream(last_post_id = null, num_requested = 0, num_header = 0, callback = () => {}) {
    return (dispatch, getState) => {
        getStateStream(getState, CONTENT_TYPES.VIDEO, last_post_id, num_requested, (success, data) => {
            if (success) {
                dispatch(setVideosStateStream(data, num_requested, num_header));
                callback(true);
            } else {
                callback(false);
            }
        });
    };
}

export function clearVideosStateStream() {
    return (dispatch) => {
        dispatch(setVideosStateStream());
    };
}

function setVideosStateStream(data, num_requested, num_header = 0) {
    return {
        type: types.SET_VIDEOS_STATE_STREAM,
        data,
        num_requested,
        num_header,
    };
}

export function getVideosPlaylists(playlists = {}, num_requested = 0, callback = () => {}) {
    return (dispatch, getState) => {
        getPlaylistStream(getState, CONTENT_TYPES.VIDEO, playlists, num_requested, (success, playlist_array) => {
            if (success) {
                dispatch(setVideosPlaylists(playlist_array));
                callback(true);
            } else {
                callback(false);
            }
        });
    };
}

export function clearVideosPlaylists() {
    return (dispatch) => {
        dispatch(setVideosPlaylists());
    };
}

function setVideosPlaylists(playlist_array = null) {
    return {
        type: types.SET_VIDEOS_PLAYLISTS,
        playlist_array,
    };
}

export function getVideosPlaylistCategories(callback = () => {}) {
    return (dispatch, getState) => {
        getPlaylistCategories(getState, CONTENT_TYPES.VIDEO, (success, data) => {
            if (success) {
                dispatch(setVideosPlaylistCategories(data));
                callback(true);
            } else {
                callback(false);
            }
        });
    };
}

export function clearVideosPlaylistCategories() {
    return (dispatch) => {
        dispatch(setVideosPlaylistCategories());
    };
}

function setVideosPlaylistCategories(data = null) {
    return {
        type: types.SET_VIDEOS_PLAYLIST_CATEGORIES,
        data,
    };
}

export function getVideoDetails(video_id, callback = () => {}) {
    return (dispatch, getState) => {
        getContentDetails(getState, video_id, CONTENT_TYPES.VIDEO, (success, content_data) => {
            if (success) {
                const data = content_data.data;
                dispatch(setVideoDetails(data));
                callback(true, data);
            } else {
                callback(false);
            }
        });
    };
}

export function clearVideoDetails() {
    return (dispatch) => {
        dispatch(setVideoDetails());
    };
}

function setVideoDetails(data = null) {
    return {
        type: types.SET_VIDEO_DETAILS,
        data,
    };
}

export function getRelatedVideos(video_id, callback = () => {}) {
    return (dispatch, getState) => {
        getRelatedContent(getState, video_id, CONTENT_TYPES.VIDEO, (success, data) => {
            if (success) {
                dispatch(setRelatedVideos(data.related_content_data));
                callback(true, data);
            } else {
                callback(false);
            }
        });
    };
}

export function clearRelatedVideos() {
    return (dispatch) => {
        dispatch(setRelatedVideos());
    };
}

function setRelatedVideos(data = null) {
    return {
        type: types.SET_RELATED_VIDEOS,
        data,
    };
}

export function setUserViewVideo(post_id, callback = () => {}) {
    return (dispatch, getState) => {
        setUserViewPost(getState, post_id, callback);
    };
}

export function setUserLikeVideo(videoDetails, callback = () => {}) {
    return (dispatch, getState) => {
        setUserLikePost(getState, videoDetails.post_id, callback);
        dispatch(setVideoDetails({ ...videoDetails, numLikes: videoDetails.numLikes + 1, isLiked: true }));
    };
}

export function setUserUnlikeVideo(videoDetails, callback = () => {}) {
    return (dispatch, getState) => {
        setUserUnlikePost(getState, videoDetails.post_id, callback);
        dispatch(setVideoDetails({ ...videoDetails, numLikes: videoDetails.numLikes - 1, isLiked: false }));
    };
}
