import {
    CONTENT_TYPES,
    getContentDetails,
    getPlaylistStream,
    getPlaylistCategories,
    getStateStream,
    getRelatedContent,
    setUserViewPost,
    setUserLikePost,
    setUserUnlikePost,
} from "ContentStream";
import * as types from "News/actions/types";

export function clearNewsContent() {
    return (dispatch) => {
        dispatch(clearNewsDetails());
        dispatch(clearNewsPlaylists());
        dispatch(clearNewsPlaylistCategories());
        dispatch(clearRelatedNews());
        dispatch(clearNewsStateStream());
    };
}

export function getNewsStateStream(last_post_id = null, num_requested = 0, num_header = 0, callback = () => {}) {
    return (dispatch, getState) => {
        getStateStream(getState, CONTENT_TYPES.NEWS, last_post_id, num_requested, (success, data) => {
            if (success) {
                dispatch(setNewsStateStream(data, num_requested, num_header));
                callback(true);
            } else {
                callback(false);
            }
        });
    };
}

export function clearNewsStateStream() {
    return (dispatch) => {
        dispatch(setNewsStateStream());
    };
}

function setNewsStateStream(data, num_requested, num_header = 0) {
    return {
        type: types.SET_NEWS_STATE_STREAM,
        data,
        num_requested,
        num_header,
    };
}

export function getNewsPlaylists(playlists = {}, num_requested = 0, callback = () => {}) {
    return (dispatch, getState) => {
        getPlaylistStream(getState, CONTENT_TYPES.NEWS, playlists, num_requested, (success, playlist_array) => {
            if (success) {
                dispatch(setNewsPlaylists(playlist_array));
                callback(true);
            } else {
                callback(false);
            }
        });
    };
}

export function clearNewsPlaylists() {
    return (dispatch) => {
        dispatch(setNewsPlaylists());
    };
}

function setNewsPlaylists(playlist_array = null) {
    return {
        type: types.SET_NEWS_PLAYLISTS,
        playlist_array,
    };
}

export function getNewsPlaylistCategories(callback = () => {}) {
    return (dispatch, getState) => {
        getPlaylistCategories(getState, CONTENT_TYPES.NEWS, (success, data) => {
            if (success) {
                dispatch(setNewsPlaylistCategories(data));
                callback(true);
            } else {
                callback(false);
            }
        });
    };
}

export function clearNewsPlaylistCategories() {
    return (dispatch) => {
        dispatch(setNewsPlaylistCategories());
    };
}

function setNewsPlaylistCategories(data = null) {
    return {
        type: types.SET_NEWS_PLAYLIST_CATEGORIES,
        data,
    };
}

export function getNewsDetails(article_id, callback = () => {}) {
    return (dispatch, getState) => {
        getContentDetails(getState, article_id, CONTENT_TYPES.NEWS, (success, content_data) => {
            if (success) {
                const data = content_data.data;
                dispatch(setNewsDetails(data));
                callback(true, data);
            } else {
                callback(false);
            }
        });
    };
}

export function clearNewsDetails() {
    return (dispatch) => {
        dispatch(setNewsDetails());
    };
}

function setNewsDetails(data = null) {
    return {
        type: types.SET_NEWS_DETAILS,
        data,
    };
}

export function getRelatedNews(article_id, callback = () => {}) {
    return (dispatch, getState) => {
        getRelatedContent(getState, article_id, CONTENT_TYPES.NEWS, (success, data) => {
            if (success) {
                dispatch(setRelatedNews(data.related_content_data));
                callback(true, data);
            } else {
                callback(false);
            }
        });
    };
}

export function clearRelatedNews() {
    return (dispatch) => {
        dispatch(setRelatedNews());
    };
}

function setRelatedNews(data = null) {
    return {
        type: types.SET_RELATED_NEWS,
        data,
    };
}

export function setUserViewArticle(post_id, callback = () => {}) {
    return (dispatch, getState) => {
        setUserViewPost(getState, post_id, callback);
    };
}

export function setUserLikeArticle(articleDetails, callback = () => {}) {
    return (dispatch, getState) => {
        setUserLikePost(getState, articleDetails.post_id, callback);
        dispatch(setNewsDetails({ ...articleDetails, numLikes: articleDetails.numLikes + 1, isLiked: true }));
    };
}

export function setUserUnlikeArticle(articleDetails, callback = () => {}) {
    return (dispatch, getState) => {
        setUserUnlikePost(getState, articleDetails.post_id, callback);
        dispatch(setNewsDetails({ ...articleDetails, numLikes: articleDetails.numLikes - 1, isLiked: false }));
    };
}
