import createReducer from "lib/createReducer";
import * as types from "Search/actions/types";

export const search = createReducer(
    {
            article_search_data: [],
            video_search_data: [],
            observation_search_data: [],
    },
    {
        [types.SET_SEARCH_RESULTS](state, action) {
            let newState = Object.assign({}, state);
            if (action.searchResults !== null) {
                if (action.searchResults.article_search_data !== null) {
                    newState.article_search_data = action.searchResults.article_search_data;
                } else {
                    newState.article_search_data = [];
                }

                if (action.searchResults.video_search_data !== null) {
                    newState.video_search_data = action.searchResults.video_search_data;
                } else {
                    newState.video_search_data = [];
                }

                if (action.searchResults.observation_search_data !== null) {
                    newState.observation_search_data = action.searchResults.observation_search_data;
                } else {
                    newState.observation_search_data = [];
                }
            } else {
                newState.article_search_data = [];
                newState.video_search_data = [];
                newState.observation_search_data = [];
            }
            return newState;
        },

    },
);
