import React from "react";
import {extensions} from "../Constants";
import "./Dropdown.scss";

export default function Dropdown({
    placeholder = "",
    placeholderValue = "",
    value = "",
    onChange = () => {},
    options = [],
    error = true,
    icon,
    fluid = false,
    className = "",
    required = false,
    label = null,
}) {
    return (
        <span className={`${className} semantic-dropdown__bounding-width${fluid === true ? "--fluid" : ""}`}>
            {label && <span className={`semantic-dropdown__label`}>{label}</span>}
            <span className={`semantic-dropdown${label ? '--has-label' : ''} ${error === true ? " semantic-dropdown--error" : ""}`}>
                {icon &&
                    typeof icon === "string" &&
                    (extensions.includes(icon.slice(-4)) ? (
                        <img src={icon} alt="Dropdown Icon" className="semantic-dropdown__icon--svg" />
                    ) : (
                        <i className={`semantic-dropdown__icon--fa fas ${icon}`} />
                    ))}
                <select
                    className={`semantic-dropdown__select`}
                    onChange={onChange}
                    value={value === null ? placeholderValue : value}
                >
                    <option className="semantic-dropdown semantic-dropdown__placeholder" value={placeholderValue} disabled hidden>
                        {placeholder}
                    </option>
                    {options.map((opt) => (
                        <option key={opt.key} value={opt.value}>
                            {opt.text}
                        </option>
                    ))}
                </select>
            </span>
        </span>
    );
}
