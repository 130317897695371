import React from "react";
import { Grid } from "react-loader-spinner";
import Column from "components/Flexbox/Column";
import { Dimmer } from "components/SemanticUI";
import "./css/react-loader-spinner.css";
import "./Loader.scss";

export default function Loader({
    customLoaderComponent = null,
    ReactLoaderComponent = Grid,
    color = "#00a300",
    secondaryColor = "#00a300",
    visible = true,
    height = 80,
    width = 80,
    contentContainerClass = "",
    wrapperStyle = {},
    wrapperClass = "",
    showLoadingLabel = true,
    loadingLabel = "Loading...",
    loadingLabelClass = "",
    dimmerEnabled = true,
    ...props
}) {
    const content =
        customLoaderComponent !== null ? (
            customLoaderComponent
        ) : (
            <Column justifyContent="center" alignItems="center" className={contentContainerClass}>
                <ReactLoaderComponent
                    color={color}
                    secondaryColor={secondaryColor}
                    height={80}
                    width={80}
                    visible={visible}
                    wrapperClass={`loader__component ${wrapperClass}`}
                    wrapperStyle={wrapperStyle}
                    {...props}
                />
                {showLoadingLabel && typeof loadingLabel === "string" && (
                    <p className={`loader__label ${loadingLabelClass}`}>{loadingLabel}</p>
                )}
            </Column>
        );

    if (dimmerEnabled) {
        return <Dimmer>{content}</Dimmer>;
    }

    return content;
}
