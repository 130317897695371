import { executeBasicNetworkCall } from "lib/Utils";
import { signIn } from "Authentication/actions/auth";

export function createLiteAccount(first, last, email, password, recaptchaToken, callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "create_lite_account",
            JSON.stringify({
                first_name: first,
                last_name: last,
                email: email,
                password: password,
                recaptchaToken: recaptchaToken,
            }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        const utag = window.utag;

                        if (utag) {
                            let tealium_dict = {
                                page_name: "Grow Smart Live",
                                page_category: "Create Account",
                                customer_email: email,
                                customer_status: "logged in",
                                customer_state: "",
                                customer_id: x.uid,
                                customer_type: "Lite",
                                event_type: "click",
                                event_name: "create_lite_account",
                                tealium_event: "create_lite_account",
                            };
                            utag.link(tealium_dict);
                        }

                        dispatch(
                            signIn({ email: email, password: password }, (successful) => {
                                callback(successful);
                            }),
                        );
                    }
                }
            },
        );
    };
}
