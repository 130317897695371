import React from "react";
import { Route, Switch } from "react-router-dom";

import { renderCustomRoute } from "ContentArea/internal/CustomRender";

import "./PublicContentAreaScreen.scss";

// Authentication
const AuthResetPasswordScreen = React.lazy(() => import("Authentication/screens/AuthResetPasswordScreen"));
const AuthForgotPasswordScreen = React.lazy(() => import("Authentication/screens/AuthForgotPasswordScreen"));
const CreateAccountScreen = React.lazy(() => import("Authentication/screens/AuthCreateAccountScreen"));
const LoginScreen = React.lazy(() => import("Authentication/screens/AuthLoginScreen"));
const TermsAndConditions = React.lazy(() => import("containers/TermsAndConditionsScreen"));
const TwoFactorScreen = React.lazy(() => import("Authentication/screens/TwoFactorAuthenticationScreen"));

const GSATScreen = React.lazy(() => import("GSAT/screens/GSATScreen"));
const PlantHealthScreen = React.lazy(() => import("PlantHealth/screens/PlantHealthScreen"));
const EditorVideoScreen = React.lazy(() => import("Videos/screens/EditorVideoScreen"));

function PublicContentAreaScreen({ props }) {
    const renderRoute = ({ Component, routeProps = {}, isWebview = false, divParent = false }) => {
        return renderCustomRoute({ Component, routeProps, isWebview, divParent, props });
    };

    const renderLogin = (routeProps) => renderRoute({ Component: LoginScreen, routeProps });
    const renderForgotPassword = (routeProps) => renderRoute({ Component: AuthForgotPasswordScreen, routeProps });
    const renderResetPassword = (routeProps) => renderRoute({ Component: AuthResetPasswordScreen, routeProps });
    const renderCreateAccount = (routeProps) => renderRoute({ Component: CreateAccountScreen, routeProps });
    const renderTermsAndConditions = (routeProps) => renderRoute({ Component: TermsAndConditions, routeProps });
    const renderTwoFactor = (routeProps) => renderRoute({ Component: TwoFactorScreen, routeProps });

    // Webview routes
    const renderGSAT = (routeProps) => renderRoute({ Component: GSATScreen, routeProps, isWebview: true });
    const renderPlantHealth = (routeProps) => renderRoute({ Component: PlantHealthScreen, routeProps, isWebview: true });

    const renderEditorVideoScreen = (routeProps) => renderRoute({ Component: EditorVideoScreen, routeProps, divParent: true });

    return (
        <div>
            <div className="public-content-area-screen">
                <div className="public-content-area-screen__container">
                    <Switch>
                        <Route exact path="/2fa" render={renderTwoFactor} />
                        <Route exact path="/forgotpassword" render={renderForgotPassword} />
                        <Route exact path="/createaccount" render={renderCreateAccount} />
                        <Route exact path="/terms_and_conditions" render={renderTermsAndConditions} />
                        <Route exact path="/mobile-gsat/:token" render={renderGSAT} />
                        <Route exact path="/mobile-plant-health/:token" render={renderPlantHealth} />
                        <Route exact path="/BASF/videos/:locator_id/:video_id" render={renderEditorVideoScreen} />
                        <Route exact path="/reset/:key" render={renderResetPassword} />
                        <Route path="/" render={renderLogin} />
                        <Route render={renderLogin} />
                    </Switch>
                </div>
            </div>
            <div className="public-content-area-screen__footer-banner" />
        </div>
    );
}

export default PublicContentAreaScreen;
