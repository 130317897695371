import React, { Suspense } from "react";
import LazyLoadingComponentPlaceholder from "ContentArea/internal/LazyLoadingComponentPlaceholder/LazyLoadingComponentPlaceholder";
import MyErrorBoundary from "ContentArea/internal/MyErrorBoundary/MyErrorBoundary";
import "../screens/ContentAreaScreen.css";

export default function CustomRender({ children, divParent = false }) {
    let content = children;

    if (divParent) {
        content = <div className="custom-render-div-parent">{children}</div>;
    }

    return (
        <MyErrorBoundary children={children}>
            <Suspense fallback={<LazyLoadingComponentPlaceholder />}>{content}</Suspense>
        </MyErrorBoundary>
    );
}

export const renderCustomRoute = ({ Component, routeProps = {}, isWebview = false, divParent = false, customProps = {}, props = {} }) => {
    const allProps = Object.assign(routeProps, props, customProps);
    if (isWebview) {
        allProps.isWebview = isWebview;
    }
    return <CustomRender children={<Component {...allProps} />} divParent={divParent} />;
};
