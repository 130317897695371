import React, { useCallback, useEffect } from 'react';
import './Modal.scss';

export default function Modal ({
    open = true,
    onClose = () => {},
    className = "",
    wrapperClassName = "",
    closeClassName = "",
    children = {}
}) {
    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            onClose();
        }
    }, [onClose]);

    useEffect(() => {
        document.addEventListener("keydown", escFunction);

        return () => {
            document.removeEventListener("keydown", escFunction);
        };
    }, [escFunction]);

    const modalClick = (e) => {
        e.stopPropagation();
    }

    const closeModal = (e) => {
        e.stopPropagation();
        onClose(e);
    }

    return (
        <React.Fragment>
            {open && (
                <div className={`semantic-modal__wrapper ${wrapperClassName}`} onClick={closeModal}>
                    <div className={`semantic-modal__modal ${className}`} onClick={modalClick}>
                        <i className={`semantic-modal__close fas fa-times ${closeClassName}`} onClick={closeModal} />
                        {children}
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};
