import { useEffect, useState } from "react";

export function useDataInitialize(
    isResponsive = false,
    num_header = 0,
    num_header_mobile = 0,
    num_stream_fetch = 0,
    stateStream = {},
    fetchStateStream = () => {},
    clearStateStream = () => {},
    num_playlists_fetch,
    playlists = {},
    fetchPlaylistCategories = () => {},
    fetchPlaylistStream = () => {},
    clearPlaylistStream = () => {},
    clearOnUnmount = false,
) {
    const contentLoading = useStateStreamInitialize(
        isResponsive,
        num_header,
        num_header_mobile,
        num_stream_fetch,
        stateStream,
        fetchStateStream,
        clearStateStream,
        clearOnUnmount,
    );

    const playlistLoading = usePlaylistsInitialize(
        isResponsive,
        num_playlists_fetch,
        playlists,
        fetchPlaylistCategories,
        fetchPlaylistStream,
        clearPlaylistStream,
        clearOnUnmount,
    );

    return [contentLoading, playlistLoading];
}

export function useStateStreamInitialize(
    isResponsive = false,
    num_header = 0,
    num_header_mobile = 0,
    num_stream_fetch = 0,
    stateStream = {
        header: [],
        data: [],
        hasMore: false,
        lastPostID: 0,
    },
    fetchStateStream = () => {}, // getNewsStateStream or getVideosStateStream or getObservationsStateStream
    clearStateStream = () => {}, // clearNewsStateStream or clearVideosStateStream or clearObservationsStateStream
    clearOnUnmount = false,
) {
    const [contentLoading, setContentLoading] = useState(true);

    useEffect(
        () => {
            const numHeader = isResponsive ? num_header_mobile : num_header;

            if (stateStream?.header && stateStream.header.length === numHeader) {
                if (!stateStream.data || stateStream.data.length < num_stream_fetch) {
                    setContentLoading(true);
                    fetchStateStream(stateStream.lastPostID, num_stream_fetch, 0, () => setContentLoading(false));
                } else {
                    setContentLoading(false);
                }
            } else {
                setContentLoading(true);
                clearStateStream();
                fetchStateStream(null, numHeader + num_stream_fetch, numHeader, () => setContentLoading(false));
            }

            return () => {
                if (clearOnUnmount) {
                    clearStateStream();
                }
            };
        },
        // eslint-disable-next-line
        [isResponsive],
    );

    return contentLoading;
}

export function usePlaylistsInitialize(
    isResponsive = false,
    num_stream_fetch = 0,
    playlists = { categories: [], data: [] },
    fetchPlaylistCategories = () => {}, // getNewsPlaylistCategories or getVideosPlaylistCategories or getObservationsPlaylistCategories
    fetchPlaylistStream = () => {}, // getNewsPlaylists or getVideosPlaylists or getObservationsPlaylists
    clearPlaylistStream = () => {}, // clearNewsPlaylists or clearVideosPlaylists or clearObservationsPlaylists
    clearOnUnmount = false,
) {
    const [playlistLoading, setPlaylistLoading] = useState(true);

    useEffect(
        () => {
            if (!playlists.categories || playlists.categories.length === 0) {
                fetchPlaylistCategories((success) => {
                    if (success) {
                        setPlaylistLoading(true);
                        clearPlaylistStream();
                        fetchPlaylistStream(playlists, num_stream_fetch, () => setPlaylistLoading(false));
                    } else {
                        setPlaylistLoading(false);
                    }
                });
            } else {
                if (!playlists.data || playlists.data.length === 0) {
                    setPlaylistLoading(true);
                    fetchPlaylistStream(playlists, num_stream_fetch, () => setPlaylistLoading(false));
                } else {
                    setPlaylistLoading(false);
                }
            }

            return () => {
                if (clearOnUnmount) {
                    clearPlaylistStream();
                }
            };
        },
        // eslint-disable-next-line
        [isResponsive],
    );

    return playlistLoading;
}
