import * as AuthActions from "./auth";
import * as CreateAccountActions from "./createAccount";
import * as ResetPasswordActions from "./resetPassword";
import * as TwoFactorAuthActions from "./twoFactorAuth";
import * as UserActions from "./user";

export const AuthActionCreators = Object.assign(
    {},
    AuthActions,
    CreateAccountActions,
    ResetPasswordActions,
    TwoFactorAuthActions,
    UserActions,
);
