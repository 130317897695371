import React from "react";
import { ReactComponent as LinxLogoSVG } from "../assets/linx-logo.svg";
import Row from "../../components/Flexbox/Row";
import "./PoweredByLinx.scss";

export default function PoweredByLinx() {
    return (
        <a href="https://www.linxsystems.com" className="powered-by-linx">
            <Row alignItems="center">
                <LinxLogoSVG width={30} height={30} />
                <span className="powered-by-linx__link">
                    powered by <span className="powered-by-linx__link--underline">Linx Systems</span>
                </span>
            </Row>
        </a>
    );
}
