import React, { useState } from "react";
import Column from "../components/Flexbox/Column";
// import Row from "../components/Flexbox/Row";
import Menu from "react-burger-menu/lib/menus/slide";
import MenuItem from "./components/MenuItem/MenuItem";
import "./Menu.scss";

import { ReactComponent as Home } from "./assets/Home.svg";
import { ReactComponent as News } from "./assets/News.svg";
import { ReactComponent as Videos } from "./assets/Videos.svg";
// import {ReactComponent as Podcasts} from './assets/Podcasts.svg'
import { ReactComponent as Observations } from "./assets/Observations.svg";
import { ReactComponent as Labels } from "./assets/Labels.svg";
// import { ReactComponent as Weather } from "./assets/Weather.svg";
// import { ReactComponent as Forums } from "./assets/Forums.svg";

import { ReactComponent as GSAT } from "./assets/GSAT.svg";
// import { ReactComponent as SmartRebates } from "./assets/SmartRebates.svg";
import { ReactComponent as PlantHealth } from "./assets/PlantHealth.svg";
import { ReactComponent as OnFarm } from "./assets/OnFarm.svg";
import { ReactComponent as LRF } from "./assets/LRF.svg";

// import { ReactComponent as MyAccount } from "./assets/MyAccount.svg";
// import { ReactComponent as AboutApp } from "./assets/AboutApp.svg";
// import { ReactComponent as LinxLogoSVG } from "./assets/linx-logo.svg";

import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import PoweredByLinx from "./components/PoweredByLinx";
import { AuthGroup, LiteLockedFeatures } from "Constants";

function SidebarMenu({ isOpen = false, handleStateChange, userGroupID }) {

    let isLite = false;
    if (userGroupID === AuthGroup.Lite) {
        isLite = true;
    }

    const menuItems = [
        {
            section: "Content",
            items: [
                { name: "Home", link: "/", icon: (props) => <Home {...props} /> },
                { name: "Articles", link: "/news", icon: (props) => <News {...props} /> },
                { name: "Videos", link: "/videos", icon: (props) => <Videos {...props} /> },
                // {name: 'Podcasts', link: '/podcasts', icon: (props) => <Podcasts {...props} />},
                { name: "Observations", link: "/observations", icon: (props) => <Observations {...props} /> },
                { name: "Labels", link: "/labels", locked: isLite ? LiteLockedFeatures.Labels : false, icon: (props) => <Labels {...props} /> },
                // { name: "Forums", link: "/forums", icon: (props) => <Forums {...props} /> },
                // { name: "Weather", link: "/weather", icon: (props) => <Weather {...props} /> },
            ],
        },
        {
            section: "Tools",
            items: [
                { name: "Advantage", link: "/gsat", locked: isLite ? LiteLockedFeatures.GSAT : false, icon: (props) => <GSAT {...props} /> },
                // { name: "Smart Rebates", link: "/smart_rebates", icon: (props) => <SmartRebates {...props} /> },
                { name: "Plant Health", link: "/plant_health", locked: isLite ? LiteLockedFeatures.PlantHealth : false, icon: (props) => <PlantHealth {...props} /> },
                { name: "RevX Fields", link: "/farm_trials", locked: isLite ? LiteLockedFeatures.FarmTrials : false, icon: (props) => <OnFarm {...props} /> },
                { name: "Forecast", link: "/lrf", locked: isLite ? LiteLockedFeatures.LRF : false, icon: (props) => <LRF {...props} /> },
            ],
        },
    ];

    const [searchTerm, setSearchTerm] = useState("");

    let history = useHistory();

    const onKeyPress = (e) => {
        if (e.key === "Enter") {
            setSearchTerm("");
            history.push("/search/" + searchTerm);
            handleStateChange(false);
        }
    };

    return (
        <Menu
            right
            isOpen={isOpen}
            onStateChange={(state) => handleStateChange(state.isOpen)}
            customBurgerIcon={false}
            customCrossIcon={false}
        >
            <Column className="sidebar-menu">
                <p className="sidebar-menu__menu-label">Menu</p>
                <Column className="sidebar-menu__content-area">
                    <input
                        className="sidebar-menu__search-bar"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onKeyPress={onKeyPress}
                    />
                    {menuItems.map((item, index) => {
                        return (
                            <Column key={index} className="sidebar-menu__section">
                                <p className="sidebar-menu__section-header">{item.section}</p>
                                {item.items.map((item2, index2) => {
                                    return (
                                        <MenuItem
                                            key={index2}
                                            name={item2.name}
                                            link={item2.link}
                                            icon={item2.icon}
                                            href={item2.href}
                                            locked={item2.locked}
                                            closeMenu={() => handleStateChange(false)}
                                        />
                                    );
                                })}
                                {index < menuItems.length - 1 && <div className="sidebar-menu__divider" />}
                            </Column>
                        );
                    })}
                    <PoweredByLinx />
                </Column>
            </Column>
        </Menu>
    );
}

function mapStateToProps(state) {
    return {
        userGroupID: state.user.groupID,
    };
}

export default connect(mapStateToProps, null, null)(SidebarMenu);
