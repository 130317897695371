import * as types from "../actions/types";

export function resetCorn() {
    return {
        type: types.RESET_CORN,
    };
}

export function resetCornFungicide() {
    return {
        type: types.RESET_CORN_FUNGICIDE,
    };
}

export function setCornSelected(data) {
    return {
        type: types.SET_CORN_SELECTED,
        data: data,
    };
}

export function setCornUseBayerSeed(data) {
    return {
        type: types.SET_CORN_USE_BAYER_SEED,
        data: data,
    };
}

export function setCornPrimarySeed(data) {
    return {
        type: types.SET_CORN_PRIMARY_SEED,
        data: data,
    };
}

export function setCornControlLevel(data) {
    return {
        type: types.SET_CORN_CONTROL_LEVEL,
        data: data,
    };
}

export function setCornApplicationMethod(data) {
    return {
        type: types.SET_CORN_APPLICATION_METHOD,
        data: data,
    };
}

export function setCornAcres(data) {
    return {
        type: types.SET_CORN_ACRES,
        data: data,
    };
}

export function setCornMarketPrice(data) {
    return {
        type: types.SET_CORN_MARKET_PRICE,
        data: data,
    };
}

export function setCornLowerUseRate(data) {
    return {
        type: types.SET_CORN_LOWER_USE_RATE,
        data,
    };
}

export function setCornFlexibleApplication(data) {
    return {
        type: types.SET_CORN_FLEXIBLE_APPLICATION,
        data,
    };
}

export function setCornReliableActivation(data) {
    return {
        type: types.SET_CORN_RELIABLE_ACTIVATION,
        data,
    };
}

export function setCornCropSafety(data) {
    return {
        type: types.SET_CORN_CROP_SAFETY,
        data,
    };
}

export function setCornBroadSpectrumResidualControl(data) {
    return {
        type: types.SET_CORN_BROAD_SPECTRUM_RESIDUAL_CONTROL,
        data,
    };
}

export function setCornBroadSpectrumKnockdown(data) {
    return {
        type: types.SET_CORN_BROAD_SPECTRUM_KNOCKDOWN,
        data,
    };
}

export function setCornResistanceManagement(data) {
    return {
        type: types.SET_CORN_RESISTANCE_MANAGEMENT,
        data,
    };
}

export function setCornFungicideSelected(data) {
    return {
        type: types.SET_CORN_FUNGICIDE_SELECTED,
        data: data,
    };
}

export function setCornFungicideBuPerAcre(data) {
    return {
        type: types.SET_CORN_FUNGICIDE_BU_PER_ACRE,
        data: data,
    };
}

export function agCalcClearCorn() {
    return {
        type: types.AG_CALC_CLEAR_CORN,
    };
}
