import { StateOptions, HOST } from "../Constants";

let shouldPrefill = true;
if (process.env.CI === true || HOST.indexOf("growsmartlive.com") !== -1 || window.location.origin.indexOf("growsmartlive.pages.dev") !== -1) {
    // if this is a build environment, then DEV_PREFILL should always be false.
    shouldPrefill = false;
}
export const DEV_PREFILL = shouldPrefill;

export { StateOptions };

export const GREEN = "#4CA844";
export const BASF_BLUE = "#004a96";
export const BLUE = "#11BEFD";

export const CornApplicationMethodOptions = [
    { key: "pre_post", text: "PRE followed by POST", value: 0 },
    { key: "pre_only", text: "PRE Only", value: 1 },
    { key: "post_only", text: "POST Only", value: 2 },
];

export const SoybeanTraitPlatformOptions = [
    { key: "xtend_flex", text: "XtendFlex®", value: 0 },
    { key: "enlist", text: "Enlist®", value: 1 },
    { key: "llgt27", text: "LibertyLink® GT27®", value: 2 },
];

export const CottonTraitPlatformOptions = [
    { key: "xtend_flex", text: "XtendFlex®", value: 0 },
    { key: "enlist", text: "Enlist®", value: 1 },
    { key: "glytol_ll", text: "GlyTol® LibertyLink®", value: 2 },
];

export const NorthernStates = [
    { key: "alaska", text: "Alaska", value: 2 },
    { key: "colorado", text: "Colorado", value: 6 },
    { key: "connecticut", text: "Connecticut", value: 7 },
    { key: "delaware", text: "Delaware", value: 8 },
    { key: "idaho", text: "Idaho", value: 12 },
    { key: "iowa", text: "Iowa", value: 15 },
    { key: "kansas", text: "Kansas", value: 16 },
    { key: "maine", text: "Maine", value: 19 },
    { key: "maryland", text: "Maryland", value: 20 },
    { key: "massachusetts", text: "Massachusetts", value: 21 },
    { key: "michigan", text: "Michigan", value: 22 },
    { key: "minnesota", text: "Minnesota", value: 23 },
    { key: "montana", text: "Montana", value: 26 },
    { key: "nebraska", text: "Nebraska", value: 27 },
    { key: "nevada", text: "Nevada", value: 28 },
    { key: "new_hampshire", text: "New Hampshire", value: 29 },
    { key: "new_jersey", text: "New Jersey", value: 30 },
    { key: "new_york", text: "New York", value: 32 },
    { key: "north_dakota", text: "North Dakota", value: 34 },
    { key: "ohio", text: "Ohio", value: 35 },
    { key: "oregon", text: "Oregon", value: 37 },
    { key: "pennsylvania", text: "Pennsylvania", value: 38 },
    { key: "rhode_island", text: "Rhode Island", value: 39 },
    { key: "south_dakota", text: "South Dakota", value: 41 },
    { key: "utah", text: "Utah", value: 44 },
    { key: "vermont", text: "Vermont", value: 45 },
    { key: "washington", text: "Washington", value: 47 },
    { key: "west_virginia", text: "West Virginia", value: 48 },
    { key: "wisconsin", text: "Wisconsin", value: 49 },
    { key: "wyoming", text: "Wyoming", value: 50 },
];

export const SouthernStates = [
    { key: "alabama", text: "Alabama", value: 1 },
    { key: "arizona", text: "Arizona", value: 3 },
    { key: "arkansas", text: "Arkansas", value: 4 },
    { key: "california", text: "California", value: 5 },
    { key: "florida", text: "Florida", value: 9 },
    { key: "georgia", text: "Georgia", value: 10 },
    { key: "hawaii", text: "Hawaii", value: 11 },
    { key: "louisiana", text: "Louisiana", value: 18 },
    { key: "mississippi", text: "Mississippi", value: 24 },
    { key: "new_mexico", text: "New Mexico", value: 31 },
    { key: "north_carolina", text: "North Carolina", value: 33 },
    { key: "oklahoma", text: "Oklahoma", value: 36 },
    { key: "south_carolina", text: "South Carolina", value: 40 },
    { key: "tennessee", text: "Tennessee", value: 42 },
    { key: "texas", text: "Texas", value: 43 },
    { key: "virginia", text: "Virginia", value: 46 },
];

export const SplitNorthSouthStates = [
    { key: "illinois", text: "Illinois", value: 13 },
    { key: "indiana", text: "Indiana", value: 14 },
    { key: "kentucky", text: "Kentucky", value: 17 },
    { key: "missouri", text: "Missouri", value: 25 },
];

export const Geography = {
    North: 0,
    South: 1,
    Unknown: 2,
};

export const Commodity = {
    Corn: "corn",
    Soybeans: "soybeans",
    Cotton: "cotton",
};

export const ControlLevel = {
    Good: "good",
    Better: "better",
    Best: "best",
    GoodHoodedSprayer: "goodhoodedsprayer",
    BestHoodedSprayer: "besthoodedsprayer",
};

export const PROGRAM_TIMINGS = {
    Pre: "PRE",
    Post: "POST",
    Post1Knockdown: "1st POST Knockdown",
    Post1Residual: "1st POST Residual",
    Post1Burndown: "1st POST Burndown",
    Post2Knockdown: "2nd POST Knockdown",
    Post2Residual: "2nd POST Residual",
    Post2: "2nd POST (if needed)",
    HoodedApplication: "Hooded Application",
};

export const CornPrimarySeeds = {
    Dekalb: "Dekalb®",
    Other: "Other",
};

export const SoybeanPrimarySeeds = {
    Credenz: "Credenz®",
    Asgrow: "Asgrow®",
    Other: "Other",
};

export const CottonPrimarySeeds = {
    Stoneville: "Stoneville®",
    FiberMax: "FiberMax®",
    Deltapine: "Deltapine®",
    Other: "Other",
};

export const BayerPrimarySeeds = ["Dekalb®", "Asgrow®", "Deltapine®"];
export const BayerTableSections = {
    Seeds: "Seeds",
    Herbicides: "Herbicides",
    Fungicides: "Fungicides",
    PartnerProducts: "Partner Products",
    LoyaltyRewards: "Loyalty Rewards",
    Totals: "Totals",
};

export const Competitors = {
    BAYER: "Bayer",
    SYNGENTA: "Syngenta",
};
