export const PrimaryColor = "#75AA3B"; // GSULive Green

export const AuthGroup = {
    AccountManager: 1,
    ProjectManager: 2,
    Author: 3,
    Viewer: 4,
    PracticeAuthor: 5,
    Advocate: 6,
    InternalViewer: 7,
    Lite: 8,
    Developer: 9,
};

export const LiteLockedFeatures = {
    Home: false,
    FarmTrials: false,
    GSAT: true,
    Labels: false,
    PlantHealth: true,
    LRF: false,
}

export const StateOptions = [
    { key: "alabama", text: "Alabama", value: 1 },
    { key: "alaska", text: "Alaska", value: 2 },
    { key: "arizona", text: "Arizona", value: 3 },
    { key: "arkansas", text: "Arkansas", value: 4 },
    { key: "california", text: "California", value: 5 },
    { key: "colorado", text: "Colorado", value: 6 },
    { key: "connecticut", text: "Connecticut", value: 7 },
    { key: "delaware", text: "Delaware", value: 8 },
    { key: "florida", text: "Florida", value: 9 },
    { key: "georgia", text: "Georgia", value: 10 },
    { key: "hawaii", text: "Hawaii", value: 11 },
    { key: "idaho", text: "Idaho", value: 12 },
    { key: "illinois", text: "Illinois", value: 13 },
    { key: "indiana", text: "Indiana", value: 14 },
    { key: "iowa", text: "Iowa", value: 15 },
    { key: "kansas", text: "Kansas", value: 16 },
    { key: "kentucky", text: "Kentucky", value: 17 },
    { key: "louisiana", text: "Louisiana", value: 18 },
    { key: "maine", text: "Maine", value: 19 },
    { key: "maryland", text: "Maryland", value: 20 },
    { key: "massachusetts", text: "Massachusetts", value: 21 },
    { key: "michigan", text: "Michigan", value: 22 },
    { key: "minnesota", text: "Minnesota", value: 23 },
    { key: "mississippi", text: "Mississippi", value: 24 },
    { key: "missouri", text: "Missouri", value: 25 },
    { key: "montana", text: "Montana", value: 26 },
    { key: "nebraska", text: "Nebraska", value: 27 },
    { key: "nevada", text: "Nevada", value: 28 },
    { key: "new_hampshire", text: "New Hampshire", value: 29 },
    { key: "new_jersey", text: "New Jersey", value: 30 },
    { key: "new_mexico", text: "New Mexico", value: 31 },
    { key: "new_york", text: "New York", value: 32 },
    { key: "north_carolina", text: "North Carolina", value: 33 },
    { key: "north_dakota", text: "North Dakota", value: 34 },
    { key: "ohio", text: "Ohio", value: 35 },
    { key: "oklahoma", text: "Oklahoma", value: 36 },
    { key: "oregon", text: "Oregon", value: 37 },
    { key: "pennsylvania", text: "Pennsylvania", value: 38 },
    { key: "rhode_island", text: "Rhode Island", value: 39 },
    { key: "south_carolina", text: "South Carolina", value: 40 },
    { key: "south_dakota", text: "South Dakota", value: 41 },
    { key: "tennessee", text: "Tennessee", value: 42 },
    { key: "texas", text: "Texas", value: 43 },
    { key: "utah", text: "Utah", value: 44 },
    { key: "vermont", text: "Vermont", value: 45 },
    { key: "virginia", text: "Virginia", value: 46 },
    { key: "washington", text: "Washington", value: 47 },
    { key: "west_virginia", text: "West Virginia", value: 48 },
    { key: "wisconsin", text: "Wisconsin", value: 49 },
    { key: "wyoming", text: "Wyoming", value: 50 },
];

// export const HOST = "https://testapi.growsmartlive.com"

let dataURL = window.location.origin;

if (window.location.origin.includes(".linxsystems.com")) {
    // dataURL = "https://alpha.growsmartlive.com";
    dataURL = "https://pc-sam-backend-gsl.internal.linxsystems.com";
    // dataURL = "https://mb-avash-backend-gsl.internal.linxsystems.com";
} else if (window.location.origin.includes(".growsmartlive.pages.dev")) {
    dataURL = "https://alpha.internal.growsmartlive.com";
    // dataURL = "https://mb-avash-backend-gsl.internal.linxsystems.com";
    // dataURL = "https://pc-sam-backend-gsl.internal.linxsystems.com";
} else if (window.location.origin.includes("localhost") && !process.env.CI) {
    /*
     You can change values in this block for local testing
     */

    // dataURL = "https://alpha.growsmartlive.com";
    dataURL = "http://localhost:8001";
} else if (window.location.origin.includes("localhost") && process.env.CI) {
    /*
    DO NOT CHANGE VALUES IN THIS BLOCK.  THIS IS FOR CIRCLECI
     */

    // we are in CircleCI
    // look for the API_SERVER bash environment variable
    if (process.env.API_SERVER) {
        dataURL = process.env.API_SERVER;
    } else {
        dataURL = "https://alpha.internal.growsmartlive.com";
    }
} else if (dataURL.includes('alpha')) {
    dataURL = "https://alpha.internal.growsmartlive.com";
} else if (dataURL.includes('beta')) {
    dataURL = "https://beta.internal.growsmartlive.com";
} else if (dataURL.includes('www.growsmartlive.com')) {
    dataURL = "https://www.internal.growsmartlive.com";
}

export const HOST = dataURL;

export const GOOGLE_SIGN_ON_CLIENTID = "363171911754-oqu59rlgl255pfpe8lvj51744c4s3d4j.apps.googleusercontent.com";
export const FACEBOOK_SIGN_IN_APPID = "4588269567880430";

export const RECAPTCHA_SITE_KEY = "6Ldv0c4ZAAAAAG8S8FSOFDS-lkQ5Jby3nXlHzfkJ";
