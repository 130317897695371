export default function SeedlingImage() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="300"
            height="300"
            viewBox="0 0 252 252"
            style={{ fill: "#000000" }}
        >
            <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1" style={{ mixBlendMode: "normal" }}>
                <path d="M0,252v-252h252v252z" fill="none"></path>
                <g>
                    <g id="surface1">
                        <path
                            d="M161.7041,95.5459c-12.6123,3.6709 -18.9082,17.86231 -11.0332,28.36231c-4.7168,2.625 -11.01269,8.38769 -16.7959,17.30859c-0.5332,-23.0918 1.5791,-45.6709 6.8291,-61.93359c7.3418,7.34179 16.2627,6.29589 24.1377,-0.53321c14.19141,-12.59179 1.5791,-42.5332 7.875,-51.96679c-7.3418,0 -36.2168,2.625 -48.2959,17.30859c-7.875,9.9873 -4.7168,24.1582 8.4082,26.7832c-7.3623,17.8418 -9.9873,43.5791 -9.9873,68.78321c-4.18359,-6.31641 -9.9668,-12.61231 -17.30859,-17.32911c14.68359,-11.54589 10.5,-29.92089 -4.73731,-37.79589c-22.5791,-12.61231 -59.8418,1.04589 -69.2959,4.71679c13.65821,8.40821 12.5918,52.5 37.7959,60.90821c16.2832,5.25 28.875,0 32.0332,-19.44141c21,15.2373 24.6709,42.5332 25.20411,43.06641c0.51269,2.625 2.625,4.71679 5.25,4.71679h0.51269c2.625,-0.5332 4.73731,-2.625 4.73731,-5.25c0,-20.46679 9.43359,-32.0332 16.26269,-37.2832c0.5332,13.125 9.4541,18.375 22.0459,17.3291c20.4873,-2.625 28.3623,-37.26269 40.44141,-42c-6.82911,-5.25 -34.125,-21 -54.07911,-15.75z"
                            fill="#00a300"
                            opacity={0.6}
                        ></path>
                        <path
                            d="M69.2959,150.15821c-25.2041,-8.40821 -24.13769,-52.5 -37.7959,-60.90821c12.0791,1.0459 30.4541,11.03321 70.3418,35.7041c-1.55859,24.1377 -14.68359,30.9668 -32.5459,25.20411zM174.8291,152.25c20.4668,-2.625 28.3418,-37.2832 40.4209,-42c-9.4541,-1.0459 -27.2959,3.6709 -61.9541,16.28321c-3.13769,19.42089 6.8291,27.29589 21.5332,25.71679zM165.375,78.2168c16.28321,-15.75 1.5791,-42.5127 7.875,-51.9668c-7.875,4.2041 -18.9082,16.7959 -37.7959,44.625c7.875,15.75 19.4209,16.28321 29.9209,7.3418z"
                            fill="#00a300"
                        ></path>
                        <path
                            d="M47.25,220.5h165.90821c4.18359,-3.6709 7.34179,-9.4541 7.34179,-15.75c0,-11.5459 -9.4541,-21 -21,-21c-2.09179,0 -4.2041,0.53321 -6.2959,1.0459c-3.1582,-9.9668 -12.0791,-16.7959 -22.5791,-16.7959c-6.8291,0 -13.125,3.15821 -17.3291,7.3418c-5.76269,-4.7168 -13.63769,-7.3418 -22.0459,-7.3418c-11.0332,0 -21,4.7168 -27.8291,12.5918c-2.625,-1.0459 -5.76269,-2.0918 -8.9209,-2.0918c-5.7832,0 -10.5,2.0918 -14.1709,5.78321c-3.1582,-3.69141 -7.3623,-5.78321 -12.0791,-5.78321c-7.34179,0 -13.125,4.7168 -15.21679,11.5459c-1.57911,-0.51269 -3.69141,-1.0459 -5.78321,-1.0459c-8.9209,0 -15.75,6.8291 -15.75,15.75c0,8.9209 6.8291,15.75 15.75,15.75z"
                            fill="#40434e"
                        ></path>
                        <path
                            d="M199.5,210c0,-8.9209 -6.8291,-15.75 -15.75,-15.75c-7.875,0 -14.1709,5.78321 -15.21679,12.5918c-4.20411,-4.18359 -9.98731,-7.3418 -16.28321,-7.3418c-6.8291,0 -13.125,3.6709 -16.7959,8.9209c-2.625,-8.38769 -10.5,-14.1709 -19.9541,-14.1709c-5.7832,0 -10.5,2.0918 -14.1709,5.78321c-3.1582,-3.69141 -7.3623,-5.78321 -12.0791,-5.78321c-7.34179,0 -13.125,4.7168 -15.21679,11.5459c-1.57911,-0.51269 -3.69141,-1.0459 -5.78321,-1.0459c-9.96679,0 -17.84179,6.8291 -20.46679,15.75h162.21679c-1.0459,-5.25 -5.25,-9.4541 -10.5,-10.5z"
                            fill="#5b5d63"
                        ></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}
