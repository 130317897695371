import { executeBasicNetworkCall } from "lib/Utils";
import { CONTENT_TYPES } from "ContentStream";
import { HOST } from "Constants";

export function getStateStream(getState, media_type, last_post_id = null, num_requested = 0, callback = () => {}) {
    const params = { media_type, num_requested };

    if (last_post_id) {
        params.last_post_id = last_post_id;
    }

    executeBasicNetworkCall(
        "get_state_stream",
        JSON.stringify(params),
        "application/json",
        "POST",
        getState,
        (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                if (x.error) {
                    console.log("get_state_stream response", x.message);
                    callback(false);
                } else {
                    const data = x.data.stream_data;
                    callback(true, data);
                }
            }
        },
        (err) => {
            console.log("get_state_stream error:", err);
            callback(false);
        },
        false,
        (status) => {
            console.log("get_state_stream bad status:", status);
            callback(false);
        },
    );
}

export function getPlaylistCategories(getState, media_type, callback = () => {}) {
    console.log("fetching playlist categories for:", media_type);

    executeBasicNetworkCall(
        "fetch_playlist_categories",
        null,
        "application/json",
        "POST",
        getState,
        (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                if (x.error) {
                    console.log("fetch_playlist_categories response", x.message);
                    callback(false);
                } else {
                    callback(true, x.data);
                }
            }
        },
        (err) => {
            console.log("fetch_playlist_categories error:", err);
            callback(false);
        },
        false,
        (status) => {
            console.log("fetch_playlist_categories bad status:", status);
            callback(false);
        },
    );
}

export function getPlaylists(getState, media_type, categories = [], callback = () => {}) {
    const params = { media_type };

    if (categories && categories.length > 0) {
        params.playlist_categories = categories;
    } else {
        callback(false);
        return;
    }

    executeBasicNetworkCall(
        "get_playlist",
        JSON.stringify(params),
        "application/json",
        "POST",
        getState,
        (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                if (x.error) {
                    console.log("get_playlist response", x.message);
                    callback(false);
                } else {
                    callback(true, x.data);
                }
            }
        },
        (err) => {
            console.log("get_playlist error:", err);
            callback(false);
        },
        false,
        (status) => {
            console.log("get_playlist bad status:", status);
            callback(false);
        },
    );
}

export function getPlaylistStream(
    getState,
    media_type,
    playlists = { categories: [], data: [] },
    num_requested = 0,
    callback = () => {},
) {
    if (num_requested <= 0 || !playlists.categories) {
        callback(false);
        return;
    }

    let categories = [].concat([...playlists.categories]);
    categories = categories.slice(playlists.data.length, playlists.data.length + num_requested);

    getPlaylists(getState, media_type, categories, (success, data) => {
        if (success) {
            callback(true, data);
        } else {
            callback(false);
        }
    });
}

export function getContentDetails(getState, media_id, media_type, callback = () => {}) {
    const params = {};

    if (media_id && media_type) {
        if (media_type === CONTENT_TYPES.NEWS) {
            params.article_id = media_id;
        } else if (media_type === CONTENT_TYPES.OBSERVATION) {
            params.observation_id = media_id;
        } else if (media_type === CONTENT_TYPES.VIDEO) {
            params.video_id = media_id;
        } else {
            callback(false);
            return;
        }
    } else {
        callback(false);
        return;
    }

    executeBasicNetworkCall(
        "get_content_details",
        JSON.stringify(params),
        "application/json",
        "POST",
        getState,
        (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                if (x.error) {
                    console.log("get_content_details response", x.message);
                    callback(false);
                } else {
                    callback(true, x.data);
                }
            }
        },
        (err) => {
            console.log("get_content_details error:", err);
            callback(false);
        },
        false,
        (status) => {
            console.log("get_content_details bad status:", status);
            callback(false);
        },
    );
}

export function getRelatedContent(getState, media_id, media_type, callback = () => {}) {
    const params = {};

    if (media_id && media_type) {
        if (media_type === CONTENT_TYPES.NEWS) {
            params.article_id = media_id;
        } else if (media_type === CONTENT_TYPES.OBSERVATION) {
            params.observation_id = media_id;
        } else if (media_type === CONTENT_TYPES.VIDEO) {
            params.video_id = media_id;
        } else {
            callback(false);
            return;
        }
    } else {
        callback(false);
        return;
    }

    executeBasicNetworkCall(
        "get_related_content",
        JSON.stringify(params),
        "application/json",
        "POST",
        getState,
        (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                if (x.error) {
                    callback(false);
                } else {
                    callback(true, x.data);
                }
            }
        },
        (err) => {
            console.log("get_related_content error:", err);
            callback(false);
        },
        false,
        (status) => {
            console.log("get_related_content bad status:", status);
            callback(false);
        },
    );
}

export function setUserViewPost(getState, post_id, callback = () => {}) {
    let json = {
        post_id: post_id,
    };
    json = JSON.stringify(json);

    executeBasicNetworkCall(
        "user_view_post",
        json,
        "application/json",
        "POST",
        getState,
        (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                callback(!x.error);
            }
        },
        `${HOST}/API/v1/UserViewPost`, // overrideURL to call from v1
    );
}

export function setUserLikePost(getState, post_id, callback = () => {}) {
    let json = {
        post_id: post_id,
    };
    json = JSON.stringify(json);

    executeBasicNetworkCall(
        "user_like_post",
        json,
        "application/json",
        "POST",
        getState,
        (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                callback(!x.error);
            }
        },
        `${HOST}/API/v1/UserLikePost`, // overrideURL to call from v1
    );
}
export function setUserUnlikePost(getState, post_id, callback = () => {}) {
    let json = {
        post_id: post_id,
    };
    json = JSON.stringify(json);

    executeBasicNetworkCall(
        "user_unlike_post",
        json,
        "application/json",
        "POST",
        getState,
        (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                callback(!x.error);
            }
        },
        `${HOST}/API/v1/UserUnlikePost`, // overrideURL to call from v1
    );
}
