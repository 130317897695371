// GENERAL
export const SET_COMPARISON_COMPETITOR = "SET_COMPARISON_COMPETITOR";
export const SET_GEOGRAPHY = "SET_GEOGRAPHY";

//CORN
export const SET_CORN_SELECTED = "SET_CORN_SELECTED";
export const SET_CORN_LOWER_USE_RATE = "SET_CORN_LOWER_USE_RATE";
export const SET_CORN_FLEXIBLE_APPLICATION = "SET_CORN_FLEXIBLE_APPLICATION";
export const SET_CORN_RELIABLE_ACTIVATION = "SET_CORN_RELIABLE_ACTIVATION";
export const SET_CORN_CROP_SAFETY = "SET_CORN_CROP_SAFETY";
export const SET_CORN_BROAD_SPECTRUM_RESIDUAL_CONTROL = "SET_CORN_BROAD_SPECTRUM_RESIDUAL_CONTROL";
export const SET_CORN_BROAD_SPECTRUM_KNOCKDOWN = "SET_CORN_BROAD_SPECTRUM_KNOCKDOWN";
export const SET_CORN_RESISTANCE_MANAGEMENT = "SET_CORN_RESISTANCE_MANAGEMENT";
export const AG_CALC_CLEAR_CORN = "AG_CALC_CLEAR_CORN";

export const SET_CORN_APPLICATION_METHOD = "SET_CORN_APPLICATION_METHOD";
export const SET_CORN_CONTROL_LEVEL = "SET_CORN_CONTROL_LEVEL";

export const SET_CORN_ACRES = "SET_CORN_ACRES";
export const SET_CORN_MARKET_PRICE = "SET_CORN_MARKET_PRICE";

export const SET_CORN_FUNGICIDE_SELECTED = "SET_CORN_FUNGICIDE_SELECTED";
export const SET_CORN_FUNGICIDE_BU_PER_ACRE = "SET_CORN_FUNGICIDE_BU_PER_ACRE";

export const SET_CORN_USE_BAYER_SEED = "SET_CORN_USE_BAYER_SEED";
export const SET_CORN_PRIMARY_SEED = "SET_CORN_PRIMARY_SEED";

//SOYBEAN
export const SET_SOYBEAN_SELECTED = "SET_SOYBEAN_SELECTED";
export const SET_SOYBEAN_LOWER_USE_RATE = "SET_SOYBEAN_LOWER_USE_RATE";
export const SET_SOYBEAN_AMARANTH_ADVANTAGE = "SET_SOYBEAN_AMARANTH_ADVANTAGE";
export const SET_SOYBEAN_BURNDOWN_ADVANTAGE = "SET_SOYBEAN_BURNDOWN_ADVANTAGE";
export const SET_SOYBEAN_EXTENDED_RESIDUAL_CONTROL = "SET_SOYBEAN_EXTENDED_RESIDUAL_CONTROL";
export const SET_SOYBEAN_RELIABLE_ACTIVATION = "SET_SOYBEAN_RELIABLE_ACTIVATION";
export const SET_SOYBEAN_CROP_SAFETY = "SET_SOYBEAN_CROP_SAFETY";
export const SET_SOYBEAN_LATE_SEASON_WEED_BREAKS = "SET_SOYBEAN_LATE_SEASON_WEED_BREAKS";
export const SET_SOYBEAN_COBRA_INJURY = "SET_SOYBEAN_COBRA_INJURY";
export const AG_CALC_CLEAR_SOBYEAN = "AG_CALC_CLEAR_SOBYEAN";
export const SET_SOYBEAN_CONTROL_LEVEL = "SET_SOYBEAN_CONTROL_LEVEL";
export const SET_SOYBEAN_TRAIT_PLATFORM = "SET_SOYBEAN_TRAIT_PLATFORM";

export const SET_TILLS = "SET_TILLS";
export const SET_USE_DICAMBA = "SET_USE_DICAMBA";

export const SET_SOYBEAN_ACRES = "SET_SOYBEAN_ACRES";
export const SET_SOYBEAN_MARKET_PRICE = "SET_SOYBEAN_MARKET_PRICE";

export const SET_SOYBEAN_FUNGICIDE_SELECTED = "SET_SOYBEAN_FUNGICIDE_SELECTED";
export const SET_SOYBEAN_FUNGICIDE_BU_PER_ACRE = "SET_SOYBEAN_FUNGICIDE_BU_PER_ACRE";

export const SET_SOYBEAN_USE_BAYER_SEED = "SET_SOYBEAN_USE_BAYER_SEED";
export const SET_SOYBEAN_PRIMARY_SEED = "SET_SOYBEAN_PRIMARY_SEED";

//COTTON
export const SET_COTTON_SELECTED = "SET_COTTON_SELECTED";
export const SET_COTTON_TILLS = "SET_COTTON_TILLS";
export const SET_COTTON_LOWER_USE_RATE = "SET_COTTON_LOWER_USE_RATE";
export const SET_COTTON_RELIABLE_ACTIVATION = "SET_COTTON_RELIABLE_ACTIVATION";
export const SET_COTTON_EXTENDED_RESIDUAL_CONTROL = "SET_COTTON_EXTENDED_RESIDUAL_CONTROL";
export const SET_COTTON_IMPROVED_QUALITY_FROM_LATE_SEASON_WEED_CONTROL =
    "SET_COTTON_IMPROVED_QUALITY_FROM_LATE_SEASON_WEED_CONTROL";
export const SET_COTTON_HERBICIDE_RESISTANCE_MANAGEMENT = "SET_COTTON_HERBICIDE_RESISTANCE_MANAGEMENT";
export const AG_CALC_CLEAR_COTTON = "AG_CALC_CLEAR_COTTON";
export const SET_USE_HOODED_SPRAYER = "SET_USE_HOODED_SPRAYER";

export const SET_COTTON_TRAIT_PLATFORM = "SET_COTTON_TRAIT_PLATFORM";
export const SET_COTTON_CONTROL_LEVEL = "SET_COTTON_CONTROL_LEVEL";

export const SET_COTTON_ACRES = "SET_COTTON_ACRES";
export const SET_COTTON_MARKET_PRICE = "SET_COTTON_MARKET_PRICE";

export const SET_COTTON_USE_BAYER_SEED = "SET_COTTON_USE_BAYER_SEED";
export const SET_COTTON_PRIMARY_SEED = "SET_COTTON_PRIMARY_SEED";

export const RESET_CORN = "RESET_CORN";
export const RESET_CORN_FUNGICIDE = "RESET_CORN_FUNGICIDE";
export const RESET_COTTON = "RESET_COTTON";
export const RESET_SOYBEAN = "RESET_SOYBEAN";
export const RESET_SOYBEAN_FUNGICIDE = "RESET_SOYBEAN_FUNGICIDE";
export const RESET_COMPETITOR = "RESET_COMPETITOR";
