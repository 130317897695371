import { useEffect, useState } from "react";
import { Multiselect } from "multiselect-react-dropdown";
import Column from "components/Flexbox/Column";
import Row from "components/Flexbox/Row";
import { Button, Dropdown, Input, Modal } from "components/SemanticUI";

import { connect } from "react-redux";
import { ActionCreators } from "redux/actions";
import { bindActionCreators } from "redux";

import { StateOptions } from "Constants";
import "./LiteToFullModal.scss";
import { validatePhoneFormat, validateZipCodeFormat } from "lib/Utils";

function LiteToFullModal({
    user = null,
    getCropsDropdownList = () => { },
    upgradeToCustomer = () => { },
    isResponsive = false,
    ...props
}) {
    const [cropOptions, setCropOptions] = useState([]);

    const [phone, setPhone] = useState('');
    const [circle, setCircle] = useState('');
    const [crops, setCrops] = useState([]);
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [stateData, setStateData] = useState({id: user.state_id, name: user.state});
    const [zip, setZip] = useState(user.zip_code);

    const [phoneError, setPhoneError] = useState(null);
    const [circleError, setCircleError] = useState(null);
    const [cropsError, setCropsError] = useState(null);
    const [addressError, setAddressError] = useState(null);

    const [hasSuccessfullyUpdated, setHasSuccessfullyUpdated] = useState(false);

    const circleOptions = [
        {text: "Grower", value: "Grower", key: 1},
        {text: "Retailer", value: "Retailer", key: 2},
        {text: "Consultant", value: "Consultant", key: 3},
    ];

    useEffect(
        () => {
            getCropsDropdownList(setCropOptions);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const validateAndUpgrade = () => {
        let errors = 0;
        if (!validatePhoneFormat(phone)) {
            errors++;
            setPhoneError(true);
        }

        if (circle === '') {
            errors++;
            setCircleError(true);
        }

        if (crops.length <= 0) {
            errors++;
            setCropsError(true);
        }

        if (street === '' || city === '' || stateData === null || !validateZipCodeFormat(zip)) {
            errors++;
            setAddressError(true);
        }
        
        if (errors === 0) {
            const cropIDs = crops.map((value, index) => { return value.key });
            upgradeToCustomer(street, city, stateData.id, zip, phone, cropIDs, circle, successCallback);
        }
    }

    const successCallback = () => {
        props.verifyUserInfo();
        setHasSuccessfullyUpdated(true);
    }

    const closeModal = () => {
        props.setOpenUpgradeModal(false);
        if (hasSuccessfullyUpdated) {
            document.location.reload(true);
        }
    }

    const renderUpdateForm = () => {
        return (
            <Column alignItems="center">
                <Row className="lite-to-full-modal__header">Upgrade to Full User</Row>
                <Row className="lite-to-full-modal__body">
                    Enter additional information to unlock Grow Smart Live features.
                </Row>
                <Row style={{ width: "100%" }} justifyContent="space-between" flexWrap={isResponsive ? 'wrap' : 'nowrap'}>
                    <Column className="lite-to-full-modal__field-column">
                        <p className="lite-to-full-modal__label">Work Phone</p>
                        <Input
                            placeholder="Work Phone"
                            value={phone}
                            onChange={(e) => {
                                setPhoneError(null);
                                setPhone(e.target.value);
                            }}
                            error={phoneError}
                            icon="fas fa-phone"
                            iconClassName="lite-to-full-modal__icon"
                            containerClassName="lite-to-full-modal__input-container"
                            fluid
                        />
                    </Column>
                    <Column className="lite-to-full-modal__field-column">
                        <p className="lite-to-full-modal__label">I am a...</p>
                        <Dropdown
                            placeholder="Select Circle"
                            options={circleOptions}
                            value={circle}
                            error={circleError}
                            onChange={(e) => {
                                setCircle(e.target.value);
                                setCircleError(null);
                            }}
                            icon="fas fa-user lite-to-full-modal__icon"
                            fluid
                        />
                    </Column>
                </Row>
                <Row style={{ width: "100%" }}>
                    <Column className="lite-to-full-modal__field-column">
                        <p className="lite-to-full-modal__label">Crops that I am interested in...</p>
                        <Multiselect
                            placeholder="Select Crops"
                            hidePlaceholder={true}
                            options={cropOptions}
                            displayValue="text"
                            onSelect={(selectedList) => { setCrops(selectedList); setCropsError(null); }}
                            onRemove={(selectedList) => { setCrops(selectedList); setCropsError(null); }}
                            closeIcon="cancel"
                            style={{
                                chips: {
                                    background: 'gray',
                                    fontSize: '16px',
                                },
                                searchBox: {
                                    margin: '10px 0px',
                                    border: '1px solid #b3b3b3',
                                    borderRadius: '5px',
                                    padding: '12px 10px 12px 10px',
                                    fontSize: '16px',
                                    backgroundColor: cropsError ? '#ffcccc' : '#fff'
                                },
                                inputField: {
                                    margin: '0px',
                                }
                            }}
                        />
                    </Column>
                </Row>

                <Row style={{ width: "100%" }}>
                    <Column className="lite-to-full-modal__field-column">
                        <p className="lite-to-full-modal__label">Business Mailing Address</p>
                        <Input
                            placeholder="Business Mailing Address"
                            value={street}
                            onChange={(e) => {
                                setAddressError(null);
                                setStreet(e.target.value);
                            }}
                            error={addressError}
                            containerClassName="lite-to-full-modal__input-container"
                            fluid
                        />
                    </Column>
                </Row>

                <Row style={{ width: "100%" }} flexWrap={isResponsive ? 'wrap' : 'nowrap'}>
                    <Column className="lite-to-full-modal__field-column">
                        <p className="lite-to-full-modal__label">City</p>
                        <Input
                            placeholder="City"
                            value={city}
                            onChange={(e) => {
                                setAddressError(null);
                                setCity(e.target.value);
                            }}
                            error={addressError}
                            containerClassName="lite-to-full-modal__input-container"
                            fluid
                        />
                    </Column>
                    <Column className="lite-to-full-modal__field-column">
                        <p className="lite-to-full-modal__label">State</p>
                        <Dropdown
                            placeholder="Select State"
                            placeholderValue={0}
                            options={StateOptions}
                            value={stateData.id}
                            error={addressError}
                            onChange={(e) => {
                                setStateData({ id: e.target.value, name: StateOptions[e.target.value - 1].text });
                                setAddressError(null);
                            }}
                            fluid
                        />
                    </Column>
                    <Column className="lite-to-full-modal__field-column">
                        <p className="lite-to-full-modal__label">ZIP Code</p>
                        <Input
                            placeholder="ZIP"
                            value={zip}
                            onChange={(e) => {
                                setAddressError(null);
                                setZip(e.target.value);
                            }}
                            error={addressError}
                            containerClassName="lite-to-full-modal__input-container"
                            fluid
                        />
                    </Column>
                </Row>

                <Row className="lite-to-full-modal__button-row" justifyContent="center">
                    <Button
                        positive
                        onClick={() => validateAndUpgrade()}
                        className="lite-to-full-modal__button"
                    >
                        Upgrade
                    </Button>
                </Row>
            </Column>
        );
    }

    const renderHasUpdated = () => {
        return (
            <Column>
                <Row className="lite-to-full-modal__header">Your Update Was Successful!</Row>
                <Row className="lite-to-full-modal__body">
                    Please enjoy the newly unlocked features and content available to you on Grow Smart Live.
                </Row>
                <Row className="lite-to-full-modal__button-row" justifyContent="center">
                    <Button
                        positive
                        inverted
                        onClick={closeModal}
                        className="lite-to-full-modal__button"
                    >
                        Continue to Grow Smart Live
                    </Button>
                </Row>
            </Column>
        );
    }

    return (
        <Modal
            onClose={closeModal}
        >
            {hasSuccessfullyUpdated ?
                renderHasUpdated()
                : renderUpdateForm()
            }
        </Modal>
    );
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
    return {
        user: state.user,
        isResponsive: state.responsive.isResponsive,
    };
}

export default connect(mapStateToProps, mapDispatchToProps, null)(LiteToFullModal);
