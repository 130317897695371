import "./Button.scss";

export default function Button({
    title = "Button",
    onClick = () => console.log("on click function"),
    className = "",
    positive = false,
    negative = false,
    inverted = false,
    disabled = false,
    children = null,
    ...otherProps
}) {
    let colorClass = negative ? "negative" : positive ? "positive" : "neutral";
    colorClass += inverted ? "-inverted" : "";

    const classNames = `semantic-button semantic-button__${colorClass} ${className}`;

    if (children) {
        return (
            <button className={classNames} onClick={onClick} disabled={disabled} {...otherProps}>
                {children}
            </button>
        );
    }

    return (
        <button className={classNames} onClick={onClick} disabled={disabled} title={title} {...otherProps}>
            {title}
        </button>
    );
}
