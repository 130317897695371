import createReducer from "../../lib/createReducer";
import * as types from "../actions/types";
import { Competitors, ControlLevel, DEV_PREFILL, Geography, SoybeanPrimarySeeds } from "GSAT/Constants";

const DEFAULT_SOYBEAN = {
    competitor: DEV_PREFILL ? Competitors.BAYER : null,
    soybeanSelected: DEV_PREFILL,
    useBayerSeed: DEV_PREFILL,
    primarySeed: DEV_PREFILL ? SoybeanPrimarySeeds.Asgrow : null,

    lowerUseRate: 0,
    amaranthAdvantage: 0,
    burndownAdvantage: 0,
    extendedResidualControl: 0,
    reliableActivation: 0,
    cropSafety: 0,
    lateSeasonWeedBreaks: 0,
    cobraInjury: 0,

    soybeanAcres: DEV_PREFILL ? 1000 : "",
    soybeanMarketPrice: 0,

    controlLevel: DEV_PREFILL ? ControlLevel.Good : null,
    traitPlatform: DEV_PREFILL ? 0 : null,

    tills: DEV_PREFILL ? true : null,
    geography: DEV_PREFILL ? Geography.North : Geography.Unknown,
    useDicamba: DEV_PREFILL ? false : null,

    herbicideTotal: 0,
};

export const agCalcSoybeanHerbicide = createReducer(DEFAULT_SOYBEAN, {
    [types.SET_COMPARISON_COMPETITOR](state, action) {
        let newState = Object.assign({}, state);
        newState.competitor = action.competitor;
        return newState;
    },

    [types.SET_SOYBEAN_SELECTED](state, action) {
        let newState = Object.assign({}, state);
        newState.soybeanSelected = action.data;
        return newState;
    },

    [types.SET_SOYBEAN_ACRES](state, action) {
        let newState = Object.assign({}, state);
        newState.soybeanAcres = action.data;
        return newState;
    },

    [types.SET_SOYBEAN_MARKET_PRICE](state, action) {
        let newState = Object.assign({}, state);
        newState.soybeanMarketPrice = action.data;
        return newState;
    },

    [types.SET_TILLS](state, action) {
        let newState = Object.assign({}, state);
        newState.tills = action.data;
        return newState;
    },

    [types.SET_GEOGRAPHY](state, action) {
        let newState = Object.assign({}, state);
        newState.geography = action.data;
        return newState;
    },

    [types.SET_SOYBEAN_CONTROL_LEVEL](state, action) {
        let newState = Object.assign({}, state);
        newState.controlLevel = action.data;
        newState.herbicideTotal = soybeanHerbicideTotal(newState);
        return newState;
    },

    [types.SET_SOYBEAN_TRAIT_PLATFORM](state, action) {
        let newState = Object.assign({}, state);
        newState.traitPlatform = action.data;
        newState.herbicideTotal = soybeanHerbicideTotal(newState);
        return newState;
    },

    [types.SET_SOYBEAN_LOWER_USE_RATE](state, action) {
        let newState = Object.assign({}, state);
        if (action.data !== null) {
            newState.lowerUseRate = action.data;
        } else {
            newState.lowerUseRate = 0;
        }
        newState.herbicideTotal = soybeanHerbicideTotal(newState);
        return newState;
    },

    [types.SET_SOYBEAN_AMARANTH_ADVANTAGE](state, action) {
        let newState = Object.assign({}, state);
        if (action.data !== null) {
            newState.amaranthAdvantage = action.data;
        } else {
            newState.amaranthAdvantage = 0;
        }
        newState.herbicideTotal = soybeanHerbicideTotal(newState);
        return newState;
    },

    [types.SET_SOYBEAN_BURNDOWN_ADVANTAGE](state, action) {
        let newState = Object.assign({}, state);
        if (action.data !== null) {
            newState.burndownAdvantage = action.data;
        } else {
            newState.burndownAdvantage = 0;
        }
        newState.herbicideTotal = soybeanHerbicideTotal(newState);
        return newState;
    },

    [types.SET_SOYBEAN_EXTENDED_RESIDUAL_CONTROL](state, action) {
        let newState = Object.assign({}, state);
        if (action.data !== null) {
            newState.extendedResidualControl = action.data;
        } else {
            newState.extendedResidualControl = 0;
        }
        newState.herbicideTotal = soybeanHerbicideTotal(newState);
        return newState;
    },

    [types.SET_SOYBEAN_RELIABLE_ACTIVATION](state, action) {
        let newState = Object.assign({}, state);
        if (action.data !== null) {
            newState.reliableActivation = action.data;
        } else {
            newState.reliableActivation = 0;
        }
        newState.herbicideTotal = soybeanHerbicideTotal(newState);
        return newState;
    },

    [types.SET_SOYBEAN_CROP_SAFETY](state, action) {
        let newState = Object.assign({}, state);
        if (action.data !== null) {
            newState.cropSafety = action.data;
        } else {
            newState.cropSafety = 0;
        }
        newState.herbicideTotal = soybeanHerbicideTotal(newState);
        return newState;
    },

    [types.SET_SOYBEAN_LATE_SEASON_WEED_BREAKS](state, action) {
        let newState = Object.assign({}, state);
        if (action.data !== null) {
            newState.lateSeasonWeedBreaks = action.data;
        } else {
            newState.lateSeasonWeedBreaks = 0;
        }
        newState.herbicideTotal = soybeanHerbicideTotal(newState);
        return newState;
    },

    [types.SET_SOYBEAN_COBRA_INJURY](state, action) {
        let newState = Object.assign({}, state);
        if (action.data !== null) {
            newState.cobraInjury = action.data;
        } else {
            newState.cobraInjury = 0;
        }
        newState.herbicideTotal = soybeanHerbicideTotal(newState);
        return newState;
    },

    [types.AG_CALC_CLEAR_SOBYEAN](state) {
        let newState = Object.assign({}, state);
        newState.lowerUseRate = 0;
        newState.amaranthAdvantage = 0;
        newState.burndownAdvantage = 0;
        newState.extendedResidualControl = 0;
        newState.reliableActivation = 0;
        newState.cropSafety = 0;
        newState.lateSeasonWeedBreaks = 0;
        newState.cobraInjury = 0;
        newState.herbicideTotal = soybeanHerbicideTotal(newState);
        return newState;
    },

    [types.SET_SOYBEAN_USE_BAYER_SEED](state, action) {
        let newState = Object.assign({}, state);
        newState.useBayerSeed = action.data;
        return newState;
    },

    [types.SET_SOYBEAN_PRIMARY_SEED](state, action) {
        let newState = Object.assign({}, state);
        newState.primarySeed = action.data;
        return newState;
    },

    [types.SET_USE_DICAMBA](state, action) {
        let newState = Object.assign({}, state);
        newState.useDicamba = action.data;
        return newState;
    },

    [types.RESET_SOYBEAN](state) {
        let newState = Object.assign({}, state);
        newState = DEFAULT_SOYBEAN;
        return newState;
    },
});

function soybeanHerbicideTotal(state) {
    if (state.competitor === Competitors.BAYER) {
        return bayerSoybeanHerbicideTotal(state);
    } else if (state.competitor === Competitors.SYNGENTA) {
        return syngentaSoybeanHerbicideTotal(state);
    } else {
        return 0;
    }
}

function bayerSoybeanHerbicideTotal(state) {
    let total = 0;

    total += state.lowerUseRate;

    if (state.controlLevel === ControlLevel.Good) {
        if (state.tills) {
            total += state.amaranthAdvantage;
        } else {
            total += state.burndownAdvantage;
        }
    } else if (state.controlLevel === ControlLevel.Better || state.controlLevel === ControlLevel.Best) {
        if (state.geography === Geography.North) {
            total += state.burndownAdvantage;
        } else {
            //south
            total += state.amaranthAdvantage;
        }
    }

    //slider 3 ------------------------------------------------------------------
    if (state.controlLevel === ControlLevel.Good || state.controlLevel === ControlLevel.Better) {
        total += state.reliableActivation;
    } else if (state.controlLevel === ControlLevel.Best) {
        if (state.geography === Geography.North) {
            total += state.extendedResidualControl;
        } else {
            //south
            total += state.reliableActivation;
        }
    }

    //slider 4
    if (state.controlLevel === ControlLevel.Good || state.controlLevel === ControlLevel.Best) {
        total += state.cropSafety;
    } else if (state.controlLevel === ControlLevel.Better) {
        total += state.lateSeasonWeedBreaks + state.cropSafety;
    }

    //slider 5
    if (state.traitPlatform !== 0) {
        total += state.cobraInjury;
    } else {
        total += 0;
    }

    return total;
}

function syngentaSoybeanHerbicideTotal(state) {
    let total = 0;

    total += state.lowerUseRate; // always included

    if (state.controlLevel === ControlLevel.Good) {
        // Good

        total += state.reliableActivation;
        if ((state.traitPlatform === 1 || state.traitPlatform === 2) && state.tills === false) {
            // Enlist No-Till or LLGT27 No-Till
            total += state.burndownAdvantage;
        }
    } else if (state.controlLevel === ControlLevel.Better) {
        // Better

        total += state.amaranthAdvantage;

        if (state.geography === Geography.North) {
            // North
            total += state.burndownAdvantage + state.extendedResidualControl;
        } else if (state.geography === Geography.South) {
            // South

            total += state.reliableActivation;
            if ((state.traitPlatform === 1 || state.traitPlatform === 2) && state.geography === Geography.South) {
                // Enlist South or LLGT27 South
                total += state.lateSeasonWeedBreaks;
            }
        }
    } else if (state.controlLevel === ControlLevel.Best) {
        // Best

        if (state.geography === Geography.North) {
            // North
            total += state.burndownAdvantage + state.extendedResidualControl;
        } else if (state.geography === Geography.South) {
            // South
            total += state.amaranthAdvantage + state.reliableActivation;
        }
    }

    return total;
}

const DEFAULT_SOYBEAN_FUNGICIDE = {
    soybeanFungicideSelected: true,
    soybeanFungicideBuPerAcre: 0,
};

export const agCalcSoybeanFungicide = createReducer(DEFAULT_SOYBEAN_FUNGICIDE, {
    [types.SET_SOYBEAN_FUNGICIDE_SELECTED](state, action) {
        let newState = Object.assign({}, state);
        newState.soybeanFungicideSelected = action.data;
        return newState;
    },

    [types.SET_SOYBEAN_FUNGICIDE_BU_PER_ACRE](state, action) {
        let newState = Object.assign({}, state);
        newState.soybeanFungicideBuPerAcre = action.data;
        return newState;
    },

    [types.RESET_SOYBEAN_FUNGICIDE](state, action) {
        let newState = Object.assign({}, state);
        newState = DEFAULT_SOYBEAN_FUNGICIDE;
        return newState;
    },
});
