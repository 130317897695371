import * as ResponsiveActions from "../../ResponsiveManager/redux/actions";
import * as EndpointActions from "./endpoints";
import * as MessagesActions from "./messages";

import { AuthActionCreators } from "Authentication/actions";
import { LRFActionCreators } from "LRF/actions";

export const ActionCreators = Object.assign(
    {},
    ResponsiveActions,
    EndpointActions,
    MessagesActions,
    AuthActionCreators,
    LRFActionCreators,
);
