import { useEffect, useState } from "react";
import { useLocation, withRouter } from "react-router-dom";

function ScrollToTop() {
    const [currentPath, setCurrentPath] = useState("/");
    const location = useLocation();

    useEffect(
        () => {
            if (location.pathname !== currentPath) {
                setCurrentPath(location.pathname);
                window.scrollTo(0, 0);
            }
        },
        // eslint-disable-next-line
        [location],
    );

    return null;
}

export default withRouter(ScrollToTop);
