import { Commodity, Competitors, Geography } from "GSAT/Constants";
import { HOST } from "Constants";
import { store } from "redux/store";
// import * as MessageActions from "actions/messages";
import { executeBasicNetworkCall } from "lib/Utils";
import "lib/obfuscate";
import * as types from "./types";
import { resetCorn, resetCornFungicide } from "./corn";
import { resetCotton } from "./cotton";
import { resetSoybean, resetSoybeanFungicide } from "./soybean";

const obfuscatedAPIKey = "mqhpoqjqkkikh pjimmolnh";

export function fetchStockPrice(commodity, callback = () => {}) {
    let symbol;

    switch (commodity) {
        case Commodity.Corn:
            symbol = "ZC^F";
            break;
        case Commodity.Soybeans:
            symbol = "ZS^F";
            break;
        case Commodity.Cotton:
            symbol = "CT^F";
            break;
        default:
            // store.dispatch(MessageActions.setError("Invalid commodity type"));
            break;
    }

    if (symbol) {
        const apiKey = obfuscatedAPIKey.defs(56);
        const endpoint = "getQuote.json?apikey=" + apiKey + "&symbols=" + symbol;
        const host = "https://ondemand.websol.barchart.com/";
        executeBasicNetworkCall(
            "",
            undefined,
            "application/json",
            "GET",
            store.getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x !== null && x.results && x.results[0]) {
                        const future = x.results[0];
                        const stockPrice = future.lastPrice / 100;
                        callback(true, stockPrice);
                    } else {
                        callback(false);
                    }
                }
            },
            (error) => {
                // store.dispatch(MessageActions.setError(error));
                callback(false);
            },
            false,
            (status) => {
                store
                    .dispatch
                    // MessageActions.setError(`Server ${status} Error`)
                    ();
                callback(false);
            },
            `${host}${endpoint}`,
            false,
        );
    }
}

export function setGeography(geography = Geography.Unknown) {
    return {
        type: types.SET_GEOGRAPHY,
        data: geography,
    };
}

export function setCompetitor(competitor = null) {
    return {
        type: types.SET_COMPARISON_COMPETITOR,
        competitor: competitor !== null && Object.values(Competitors).includes(competitor) ? competitor : null,
    };
}

export function authenticateGSATUser(auth_token, callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "authenticate_gsat_user",
            JSON.stringify({ auth_token }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
            `${HOST}/API/v1/gsat/AuthenticateGSATUser`,
        );
    };
}

export function resetCompetitor() {
    return {
        type: types.RESET_COMPETITOR,
    };
}

export function clearGSAT() {
    return (dispatch) => {
        dispatch(resetCorn());
        dispatch(resetCornFungicide());
        dispatch(resetCotton());
        dispatch(resetSoybean());
        dispatch(resetSoybeanFungicide());
        dispatch(resetCompetitor());
    };
}
