import ContentLoader from "react-content-loader";
import Column, { CenteredColumn } from "components/Flexbox/Column";
import ContentPreview from "../ContentPreviewDefault";
import "./CarouselCard.scss";

export default function CarouselCard({
    cardID = "",
    card = { id: -1, image: null, type: null },
    onCardClick = () => {},
    onViewAll = () => {},
    CardPreviewComponent = ContentPreview,
    cardContainerClass = "",
    cardClass = "",
    isResponsive = false,
    isPadded = false,
    iPhoneSEMql = false,
    active = false,
    isLoading = false,
    ...cardProps
}) {
    const onClick = card.overrideClick ? card.overrideClick : onCardClick;

    if (card.type === "viewAll") {
        return (
            <Column
                id={cardID}
                className={`carousel-card${isResponsive ? "--mobile" : ""} ${active ? "carousel-card--active" : ""} ${
                    cardProps.viewAllContainerClass ?? ""
                }`}
                justifyContent="center"
                alignItems="center"
                onClick={onViewAll}
            >
                <div className={`carousel-card__view-all ${cardProps.viewAllClass ?? ""}`}>View All</div>
            </Column>
        );
    }

    if (isLoading) {
        return (
            <CenteredColumn className={cardProps.contentLoaderContainerClass ?? ""}>
                <ContentLoader className={cardProps.contentLoaderClass ?? ""}>
                    <rect x="5" y="0" rx="5" ry="5" width="calc(100% - 10px)" height="100%" />
                </ContentLoader>
            </CenteredColumn>
        );
    }

    return (
        <div
            id={cardID}
            className={`carousel-card${iPhoneSEMql ? "--mobile" : ""} ${
                active ? "carousel-card--active" : ""
            } ${cardContainerClass}`}
        >
            <CardPreviewComponent
                onClick={() => onClick(card.id, cardID)}
                isResponsive={isResponsive}
                isPadded={isPadded}
                iPhoneSEMql={iPhoneSEMql}
                containerClassName={cardClass}
                {...card}
                {...cardProps}
            />
        </div>
    );
}
