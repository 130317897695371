export const LOGOUT = "LOGOUT";
export const SET_AGREE_TERMS_AND_CONDITIONS = "SET_AGREE_TERMS_AND_CONDITIONS";
export const SET_LOGIN = "SET_LOGIN";
export const SET_IS_LOGGED_IN_STATUS = "SET_IS_LOGGED_IN_STATUS";
export const SET_OPEN_UPGRADE_MODAL = "SET_OPEN_UPGRADE_MODAL";

// user
export const SET_ZIP_CODE = "SET_ZIP_CODE";
export const SET_USER_STATE = "SET_USER_STATE";
export const SET_USER_COUNTY = "SET_USER_COUNTY";
