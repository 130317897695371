import * as types from "../actions/types";

export function resetCotton() {
    return {
        type: types.RESET_COTTON,
    };
}

export function setCottonSelected(data) {
    return {
        type: types.SET_COTTON_SELECTED,
        data: data,
    };
}

export function setCottonTills(tills = false) {
    return {
        type: types.SET_COTTON_TILLS,
        data: tills,
    };
}

export function setCottonUseBayerSeed(data) {
    return {
        type: types.SET_COTTON_USE_BAYER_SEED,
        data: data,
    };
}

export function setCottonPrimarySeed(data) {
    return {
        type: types.SET_COTTON_PRIMARY_SEED,
        data: data,
    };
}

export function setCottonControlLevel(data) {
    return {
        type: types.SET_COTTON_CONTROL_LEVEL,
        data: data,
    };
}

export function setCottonTraitPlatform(data) {
    return {
        type: types.SET_COTTON_TRAIT_PLATFORM,
        data: data,
    };
}
export function setUsesHoodedSprayer(uses = false) {
    return {
        type: types.SET_USE_HOODED_SPRAYER,
        data: uses,
    };
}

export function setCottonAcres(data) {
    return {
        type: types.SET_COTTON_ACRES,
        data: data,
    };
}

export function setCottonMarketPrice(data) {
    return {
        type: types.SET_COTTON_MARKET_PRICE,
        data: data,
    };
}

export function setCottonLowerUseRate(data) {
    return {
        type: types.SET_COTTON_LOWER_USE_RATE,
        data,
    };
}

export function setCottonReliableActivation(data) {
    return {
        type: types.SET_COTTON_RELIABLE_ACTIVATION,
        data,
    };
}

export function setCottonExtendedResidualControl(data) {
    return {
        type: types.SET_COTTON_EXTENDED_RESIDUAL_CONTROL,
        data,
    };
}

export function setCottonImprovedQualityFromLateSeasonWeedControl(data) {
    return {
        type: types.SET_COTTON_IMPROVED_QUALITY_FROM_LATE_SEASON_WEED_CONTROL,
        data,
    };
}

export function setCottonHerbicideResistanceManagement(data) {
    return {
        type: types.SET_COTTON_HERBICIDE_RESISTANCE_MANAGEMENT,
        data,
    };
}

export function agCalcClearCotton() {
    return {
        type: types.AG_CALC_CLEAR_COTTON,
    };
}
