import "./Input.scss";
import { createEnum } from "lib/Utils";
import {extensions} from "../Constants";

const POS_ENUMS = createEnum(["left", "right"]);

export default function Input({
    placeholder = "",
    value = "",
    onChange = () => {},
    error = true,
    className = "",
    containerClassName = "",
    label,
    readonly = false,
    id = "linx-ui-input",
    fluid = false,
    icon,
    iconClassName = "",
    iconPosition = POS_ENUMS.left,
    ...inputProps
}) {
    let classNameBuilder = "semantic-input";

    if (fluid) {
        classNameBuilder += " semantic-input--fluid";
    }

    if (icon) {
        classNameBuilder += ` semantic-input--icon-${iconPosition}`;
    }

    if (error) {
        classNameBuilder += " semantic-input--error";
    }

    classNameBuilder += className;

    return (
        <li className={`form-row ${containerClassName}`}>
            {label && <label htmlFor={label}>{label}:</label>}
            {icon && typeof icon === "string" && (extensions.includes(icon.slice(-4))) ? (
                <img src={icon} alt="Dropdown Icon" className="semantic-dropdown__icon--svg" />
            ) : (
                <i className={`form-row__icon--${iconPosition} semantic-input__icon fas ${icon} ${iconClassName}`}/>
            )}
            <input placeholder={placeholder} value={value} onChange={onChange} className={classNameBuilder} {...inputProps} />
        </li>
    );
}
