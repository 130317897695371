import * as types from "./types";

export function setIsResponsive(isResponsive = false) {
    return {
        type: types.SET_IS_RESPONSIVE,
        isResponsive,
    };
}

export function setIsPadded(isPadded = false) {
    return {
        type: types.SET_IS_PADDED,
        isPadded,
    };
}

export function setIPhoneSEMql(iPhoneSEMql = false) {
    return {
        type: types.SET_IPHONE_SE_MQL,
        iPhoneSEMql,
    };
}

export function setGSATMobileMql(isGsatResponsive = false) {
    return {
        type: types.SET_GSAT_RESPONSIVE_MQL,
        isGsatResponsive,
    };
}
