import React, { useEffect } from "react";
import Column from "components/Flexbox/Column";
import { disableScroll, enableScroll } from "lib/ScrollUtils";
import "./Dimmer.scss";

export default function Dimmer({
    className = "",
    children = null,
    justifyContent = "center",
    alignItems = "center",
    scrollEnabled = false,
}) {
    useEffect(() => {
        if (!scrollEnabled) {
            disableScroll();
        }
        return () => enableScroll();
    }, [scrollEnabled]);

    return (
        <Column className={`custom-semantic-dimmer ${className}`} justifyContent={justifyContent} alignItems={alignItems}>
            {children}
        </Column>
    );
}
