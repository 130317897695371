import { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { connect } from "react-redux";
import { ActionCreators } from "redux/actions";
import { bindActionCreators } from "redux";

import Column from "./components/Flexbox/Column";
import TopBar from "./components/TopBar/TopBar";
import ContentArea from "./ContentArea/screens/ContentAreaScreen";
import PublicContentArea from "ContentArea/screens/PublicContentAreaScreen";
import ResponsiveManager from "./ResponsiveManager";
import ScrollToTop from "./ScrollToTop";
import "./App.css";

const renderAuthenticated = () => {
    return (
        <>
            <TopBar />
            <ContentArea />
        </>
    );
};

const renderPublic = () => {
    return <PublicContentArea />;
};

function App(props) {

    useEffect(
        () => {
            props.getEndpoints();
        }, [props]);

    return (
        <Column>
            <Router>
                <ScrollToTop />
                {props.isLoggedIn ? renderAuthenticated() : renderPublic()}
            </Router>
            <ResponsiveManager />
        </Column>
    );
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
    return {
        isLoggedIn: state.user.isLoggedIn,
    };
}

export default connect (mapStateToProps, mapDispatchToProps) (App);
