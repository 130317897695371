import { setStateStream, setPlaylists, setPlaylistCategories, setContentDetails, setRelatedContent } from "ContentStream";
import createReducer from "lib/createReducer";
import * as types from "Videos/actions/types";

export const videos = createReducer(
    {
        stateStream: {
            header: [],
            data: [],
            hasMore: false,
            lastPostID: null,
        },
        playlists: {
            categories: [],
            data: [],
        },
        details: {
            data: null,
            related: []
        },
    },
    {
        [types.SET_VIDEOS_STATE_STREAM](state, action) {
            return setStateStream(state, action);
        },

        [types.SET_VIDEOS_PLAYLISTS](state, action) {
            return setPlaylists(state, action);
        },

        [types.SET_VIDEOS_PLAYLIST_CATEGORIES](state, action) {
            return setPlaylistCategories(state, action);
        },

        [types.SET_VIDEO_DETAILS](state, action) {
            return setContentDetails(state, action);
        },

        [types.SET_RELATED_VIDEOS](state, action) {
            return setRelatedContent(state, action);
        },
    },
);
