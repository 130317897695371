import {
    CONTENT_TYPES,
    getContentDetails,
    getPlaylistStream,
    getPlaylistCategories,
    getStateStream,
    getRelatedContent,
    setUserViewPost,
    setUserLikePost,
    setUserUnlikePost,
} from "ContentStream";
import * as types from "Observations/actions/types";

export function clearObservationsContent() {
    return (dispatch) => {
        dispatch(clearObservationDetails());
        dispatch(clearObservationsPlaylists());
        dispatch(clearObservationsPlaylistCategories());
        dispatch(clearRelatedObservations());
        dispatch(clearObservationsStateStream());
    };
}

export function getObservationsStateStream(last_post_id = null, num_requested = 0, num_header = 0, callback = () => {}) {
    return (dispatch, getState) => {
        getStateStream(getState, CONTENT_TYPES.OBSERVATION, last_post_id, num_requested, (success, data) => {
            if (success) {
                dispatch(setObservationsStateStream(data, num_requested, num_header));
                callback(true);
            } else {
                callback(false);
            }
        });
    };
}

export function clearObservationsStateStream() {
    return (dispatch) => {
        dispatch(setObservationsStateStream());
    };
}

function setObservationsStateStream(data, num_requested, num_header = 0) {
    return {
        type: types.SET_OBSERVATIONS_STATE_STREAM,
        data,
        num_requested,
        num_header,
    };
}

export function getObservationsPlaylists(playlists = {}, num_requested = 0, callback = () => {}) {
    return (dispatch, getState) => {
        getPlaylistStream(getState, CONTENT_TYPES.OBSERVATION, playlists, num_requested, (success, playlist_array) => {
            if (success) {
                dispatch(setObservationsPlaylists(playlist_array));
                callback(true);
            } else {
                callback(false);
            }
        });
    };
}

export function clearObservationsPlaylists() {
    return (dispatch) => {
        dispatch(setObservationsPlaylists());
    };
}

function setObservationsPlaylists(playlist_array = null) {
    return {
        type: types.SET_OBSERVATIONS_PLAYLISTS,
        playlist_array,
    };
}

export function getObservationsPlaylistCategories(callback = () => {}) {
    return (dispatch, getState) => {
        getPlaylistCategories(getState, CONTENT_TYPES.OBSERVATION, (success, data) => {
            if (success) {
                dispatch(setObservationsPlaylistCategories(data));
                callback(true);
            } else {
                callback(false);
            }
        });
    };
}

export function clearObservationsPlaylistCategories() {
    return (dispatch) => {
        dispatch(setObservationsPlaylistCategories());
    };
}

function setObservationsPlaylistCategories(data = null) {
    return {
        type: types.SET_OBSERVATIONS_PLAYLIST_CATEGORIES,
        data,
    };
}

export function getObservationDetails(observation_id, callback = () => {}) {
    return (dispatch, getState) => {
        getContentDetails(getState, observation_id, CONTENT_TYPES.OBSERVATION, (success, content_data) => {
            if (success) {
                const data = content_data.data;
                dispatch(setObservationDetails(data));
                callback(true, data);
            } else {
                callback(false);
            }
        });
    };
}

export function clearObservationDetails() {
    return (dispatch) => {
        dispatch(setObservationDetails());
    };
}

function setObservationDetails(data = null) {
    return {
        type: types.SET_OBSERVATION_DETAILS,
        data,
    };
}

export function getRelatedObservations(observation_id, callback = () => {}) {
    return (dispatch, getState) => {
        getRelatedContent(getState, observation_id, CONTENT_TYPES.OBSERVATION, (success, data) => {
            if (success) {
                dispatch(setRelatedObservations(data.related_content_data));
                callback(true, data);
            } else {
                callback(false);
            }
        });
    };
}

export function clearRelatedObservations() {
    return (dispatch) => {
        dispatch(setRelatedObservations());
    };
}

function setRelatedObservations(data = null) {
    return {
        type: types.SET_RELATED_OBSERVATIONS,
        data,
    };
}

export function setUserViewObservation(post_id, callback = () => {}) {
    return (dispatch, getState) => {
        setUserViewPost(getState, post_id, callback);
    };
}

export function setUserLikeObservation(post_id, observationDetails = null, callback = () => {}) {
    return (dispatch, getState) => {
        setUserLikePost(getState, post_id, callback);
        // update redux in detail view
        if (observationDetails !== null) {
            dispatch(setObservationDetails({ ...observationDetails, numLikes: observationDetails.numLikes + 1, isLiked: true }));
        }
    };
}

export function setUserUnlikeObservation(post_id, observationDetails = null, callback = () => {}) {
    return (dispatch, getState) => {
        setUserUnlikePost(getState, post_id, callback);
        // update redux in detail view
        if (observationDetails !== null) {
            dispatch(setObservationDetails({ ...observationDetails, numLikes: observationDetails.numLikes - 1, isLiked: false }));
        }
    };
}
