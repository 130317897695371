import "./Checkbox.scss";

export default function Checkbox({ className = "", checked = false, onChange = () => {}, ...otherProps }) {
    return (
        <span className={`semantic-checkbox ${className}`} {...otherProps}>
            <span
                className={`semantic-checkbox__span ${checked ? "semantic-checkbox__span--selected" : ""}`}
                value={checked}
                onClick={onChange}
            >
                {checked && <i className="fa fa-check semantic-checkbox__check-icon" aria-hidden="true" />}
            </span>
        </span>
    );
}
