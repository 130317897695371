import React, { useCallback, useRef } from "react";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";

import Column, { CenteredColumn } from "components/Flexbox/Column";
import CategoryCarousel from "components/CategoryCarousel";
import Loader from "components/SemanticUI/Loader";

import { CONTENT_TYPES } from "ContentStream/lib/Constants";
import Seedling from "ContentStream/assets/Seedling";
import "./ContentStream.scss";

function ContentStream({
    // header
    headerTitle = null,
    headerContent = null,
    headerContainerClass = "",
    headerTitleClass = "",
    contentLoading = false,
    // stream
    streamContent = [],
    streamContentContainerClass = "",
    streamContentClass = "",
    hasMoreStreamContent = false,
    loadMoreStreamContent = () => {},
    moreLoading = false,
    StreamItem = <></>,
    streamItemProps = {},
    numColumns = 2,
    numItemsPerSection = 6,
    displayByRow = true,
    contentKey = "post_id",
    contentSize = "",
    contentType = "",
    // playlist
    playlists = [],
    playlistCategories = [],
    morePlaylistsLoading = false,
    loadMorePlaylists = () => {},
    playlistLoading = false,
    playlistContainerClass = "",
    carouselCustomClasses = {},
    carouselCardProps = {},
    // no content
    emptyContentRender = null,
    // responsiveness
    isResponsive = false,
    isPadded = true,
    iPhoneSEMql = false,
    responsiveNumColumns = true,
}) {
    let gridColumns = numColumns < 1 || numColumns > 3 ? 1 : numColumns;

    if (gridColumns === 3 && responsiveNumColumns) {
        // videos
        gridColumns = isResponsive ? 1 : !isPadded ? gridColumns - 1 : gridColumns;
    } else if (gridColumns === 2 && responsiveNumColumns) {
        // news
        gridColumns = isResponsive && !isPadded ? gridColumns - 1 : gridColumns;
    }
    // else gridColumns === 1 // observations

    const streamObserver = useRef(); // (*)
    const playlistObserver = useRef(); // (*)

    const lastElementRef = useCallback(
        // (*)
        (node) => {
            if (moreLoading) {
                return;
            }

            if (streamObserver.current) {
                streamObserver.current.disconnect();
            }

            /*
                Asynchronously observe changes in the intersection of a target element with an ancestor element or with a top-level document’s viewport.
            */
            streamObserver.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMoreStreamContent) {
                    loadMoreStreamContent();
                }
            });

            if (node) {
                streamObserver.current.observe(node);
            }
        },
        [moreLoading, hasMoreStreamContent, loadMoreStreamContent],
    );

    const lastPlaylistRef = useCallback(
        // (*)
        (node) => {
            if (morePlaylistsLoading) {
                return;
            }

            if (playlistObserver.current) {
                playlistObserver.current.disconnect();
            }

            const numContentSections = Math.ceil(streamContent.length / numItemsPerSection);
            const hasMorePlaylists = playlists.length < playlistCategories.length;
            /*
                Asynchronously observe changes in the intersection of a target element with an ancestor element or with a top-level document’s viewport.
            */
            playlistObserver.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMorePlaylists) {
                    if (playlists.length < numContentSections) {
                        loadMorePlaylists(numContentSections - playlists.length);
                    } else if (!hasMoreStreamContent) {
                        loadMorePlaylists(playlistCategories.length - playlists.length);
                    }
                }
            });

            if (node) {
                playlistObserver.current.observe(node);
            }
        },
        [
            morePlaylistsLoading,
            hasMoreStreamContent,
            streamContent,
            numItemsPerSection,
            playlists,
            playlistCategories,
            loadMorePlaylists,
        ],
    );

    const renderSinglePlaylist = (playlist = {}, index = 0) => {
        let isLastPlaylist = playlists[index] && playlist && JSON.stringify(playlists[index]) === JSON.stringify(playlist);

        return (
            <div
                key={index}
                className={`content-stream__playlist-container ${playlistContainerClass}`}
                ref={isLastPlaylist ? lastPlaylistRef : null}
            >
                <CategoryCarousel
                    categoryTitle={playlist.category}
                    categoryData={playlist.data}
                    isResponsive={isResponsive}
                    isPadded={isPadded}
                    isMobile={iPhoneSEMql}
                    CardPreviewComponent={StreamItem}
                    size="playlist"
                    isLoading={playlistLoading}
                    {...carouselCustomClasses}
                    {...carouselCardProps}
                />
            </div>
        );
    };

    const renderStreamSectionWithPlaylist = (index = 0, streamSection = null) => {
        return (
            <Column key={index} className={`content-stream ${streamContentContainerClass}`}>
                {streamSection}
                {((index < playlists.length && playlists[index]) || playlistLoading) &&
                    renderSinglePlaylist(playlists[index], index)}
            </Column>
        );
    };

    const renderGrid = (items = [], index = 0, gridStyle = {}) => (
        <div
            key={`grid-${index}`}
            className={`content-stream__grid-container--col-${gridColumns} ${streamContentClass}`}
            style={gridStyle}
        >
            {items.map((content, i) => {
                const id = content[contentKey];
                let isLastElement = false;
                if (id) {
                    isLastElement = streamContent[streamContent.length - 1][contentKey] === id;
                } else {
                    isLastElement = streamContent.length - 1 === i;
                }

                return (
                    <div
                        id={`content-${id}-${i}`}
                        key={id ?? i}
                        ref={isLastElement ? lastElementRef : null}
                        className={`content-stream__grid-item-container--col-${gridColumns}`}
                    >
                        <StreamItem {...content} {...streamItemProps} size={contentSize} />
                    </div>
                );
            })}
        </div>
    );

    const renderInfiniteStream = () => {
        const splitContent = streamContent.reduce(function (accumulator, currentValue, currentIndex, array) {
            if (currentIndex % numItemsPerSection === 0) {
                accumulator.push(array.slice(currentIndex, currentIndex + numItemsPerSection));
            }
            return accumulator;
        }, []);

        const numStreamSections = splitContent.length;
        const numPlaylists = playlists.length;

        return (
            <>
                {splitContent.map((sectionItems, index) => {
                    const streamSection = displayByRow
                        ? sectionItems
                              .reduce(function (accumulator, currentValue, currentIndex, array) {
                                  if (currentIndex % gridColumns === 0) {
                                      accumulator.push(array.slice(currentIndex, currentIndex + gridColumns));
                                  }
                                  return accumulator;
                              }, [])
                              .map((items, i) => {
                                  const numColInRow = items.length % gridColumns === 0 ? gridColumns : items.length % gridColumns;
                                  const gridStyle = {
                                      gridTemplateColumns:
                                          numColInRow === 1
                                              ? `${100 / gridColumns}%`
                                              : numColInRow === 2
                                              ? `${100 / gridColumns}% ${100 / gridColumns}%`
                                              : `${100 / gridColumns}% ${100 / gridColumns}% ${100 / gridColumns}%`,
                                  };

                                  return renderGrid(items, i, gridStyle);
                              })
                        : renderGrid(sectionItems, index);

                    return renderStreamSectionWithPlaylist(index, streamSection);
                })}
                {numStreamSections < numPlaylists &&
                    [...Array(numPlaylists - numStreamSections)].map((el, i) =>
                        renderStreamSectionWithPlaylist(i + numStreamSections),
                    )}
                {moreLoading && renderContentLoaderRow()}
            </>
        );
    };

    const renderContentLoaderRow = () => (
        <div className={`content-stream__grid-container--col-${gridColumns} ${streamContentClass}`}>
            {[...Array(gridColumns)].map((a, index) => renderContentLoader(index))}
        </div>
    );

    const renderContentLoader = (index) => {
        let loader = null;

        switch (contentSize) {
            case "vertical":
                if (contentType === CONTENT_TYPES.OBSERVATION) {
                    loader = (
                        <ContentLoader className="content-stream">
                            <rect x="15%" y="10" rx="50" ry="50" width="50" height="50" />
                            <rect x="15%" y="20" rx="5" ry="5" transform="translate(60, 0)" width="200" height="10" />
                            <rect x="15%" y="40" rx="5" ry="5" transform="translate(60, 0)" width="150" height="10" />
                            <rect x="15%" y="70" rx="5" ry="5" width="70%" height="60%" />
                            <rect x="15%" y="88%" rx="5" ry="5" width="70%" height="10%" />
                        </ContentLoader>
                    );
                } else {
                    loader = (
                        <ContentLoader className="content-stream">
                            <rect x="10" y="10" rx="5" ry="5" width="calc(100% - 20px)" height="50%" />
                            <rect x="20" y="60%" rx="5" ry="5" width="calc(100% - 40px)" height="10%" />
                            <rect x="20" y="78%" rx="50" ry="50" width="50" height="50" />
                            <rect x="80" y="81%" rx="5" ry="5" width="200" height="10" />
                            <rect x="80" y="88%" rx="5" ry="5" width="150" height="10" />
                        </ContentLoader>
                    );
                }
                break;
            case "horizontal":
                loader = (
                    <ContentLoader className="content-stream">
                        <rect x="10" y="10" rx="5" ry="5" width="30%" height="calc(100% - 20px)" />
                        <rect x="35%" y="20" rx="4" ry="4" width="60%" height="15" />
                        <rect x="35%" y="45" rx="4" ry="4" width="55%" height="10" />
                        <rect x="35%" y="88" rx="50" ry="50" width="50" height="50" />
                        <rect x="35%" y="98" rx="3" ry="3" transform="translate(60, 0)" width="150" height="10" />
                        <rect x="35%" y="118" rx="3" ry="3" transform="translate(60, 0)" width="120" height="10" />
                    </ContentLoader>
                );
                break;
            case "mobile":
                if (contentType === CONTENT_TYPES.NEWS) {
                    loader = (
                        <ContentLoader className="content-stream">
                            <rect x="10" y="10" rx="5" ry="5" width="30%" height="calc(100% - 20px)" />
                            <rect x="35%" y="20" rx="4" ry="4" width="60%" height="15" />
                            <rect x="35%" y="45" rx="4" ry="4" width="55%" height="10" />
                            <rect x="35%" y="62" rx="50" ry="50" width="50" height="50" />
                            <rect
                                x="35%"
                                y="72"
                                rx="3"
                                ry="3"
                                transform="translate(60, 0)"
                                width="calc(65% - 100px)"
                                height="10"
                            />
                            <rect
                                x="35%"
                                y="92"
                                rx="3"
                                ry="3"
                                transform="translate(60, 0)"
                                width="calc(65% - 140px)"
                                height="10"
                            />
                        </ContentLoader>
                    );
                } else {
                    loader = null;
                }
                break;
            case "widget":
                loader = (
                    <ContentLoader className="content-stream">
                        <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
                    </ContentLoader>
                );
                break;
            default:
                break;
        }

        return (
            <Column
                key={`content-loader-${index}`}
                className={`content-stream__loading-grid-item--${contentSize}`}
                justifyContent="center"
                alignItems="center"
            >
                {loader}
            </Column>
        );
    };

    const renderHeader = () => (
        <Column
            className={`content-stream__stream-header-container${
                !headerContent ? "--no-header" : isResponsive ? "--mobile" : ""
            } ${headerContainerClass}`}
            alignItems={isResponsive ? "center" : "flex-start"}
        >
            {headerTitle && <p className={`content-stream__stream-header ${headerTitleClass}`}>{headerTitle}</p>}
            {headerContent}
        </Column>
    );

    if (!contentLoading && !headerContent && streamContent.length === 0 && playlists.length === 0) {
        if (emptyContentRender) {
            return emptyContentRender;
        } else {
            return (
                <Column className="content-stream__no-content-main">
                    <CenteredColumn className="content-stream__no-content-container">
                        <Seedling />
                        <p className="content-stream__no-content-text">No Content Published</p>
                    </CenteredColumn>
                </Column>
            );
        }
    }

    return (
        <Column className="content-stream">
            {contentLoading && <Loader />}
            {(headerTitle || headerContent) && renderHeader()}
            {renderInfiniteStream()}
        </Column>
    );
}

function mapStateToProps(state) {
    return {
        isResponsive: state.responsive.isResponsive,
        isPadded: state.responsive.isPadded,
        iPhoneSEMql: state.responsive.iPhoneSEMql,
    };
}

export default connect(mapStateToProps, null, null)(ContentStream);
