import { setStateStream, setPlaylists, setPlaylistCategories, setContentDetails, setRelatedContent } from "ContentStream";
import createReducer from "lib/createReducer";
import * as types from "Observations/actions/types";

export const observations = createReducer(
    {
        stateStream: {
            header: [],
            data: [],
            hasMore: false,
            lastPostID: null,
        },
        playlists: {
            categories: [],
            data: [],
        },
        details: {
            data: null,
            related: [],
        },
    },
    {
        [types.SET_OBSERVATIONS_STATE_STREAM](state, action) {
            return setStateStream(state, action);
        },

        [types.SET_OBSERVATIONS_PLAYLISTS](state, action) {
            return setPlaylists(state, action);
        },

        [types.SET_OBSERVATIONS_PLAYLIST_CATEGORIES](state, action) {
            return setPlaylistCategories(state, action);
        },

        [types.SET_OBSERVATION_DETAILS](state, action) {
            return setContentDetails(state, action);
        },

        [types.SET_RELATED_OBSERVATIONS](state, action) {
            return setRelatedContent(state, action);
        },
    },
);
